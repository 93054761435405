import { produce } from 'immer';
import uniq from 'lodash/uniq';
import { create } from 'zustand';

import { FollowUpQuestionSchema } from '@/app/schema/follow-up-question';
import type {
  MessengerChat,
  MessengerContact,
} from '@/components/messenger/types';
import { AIFunctionAnimationStatus, Screen } from '@/context/messengerContext';
import { SaveQuestionResponse } from '@/helpers/saveQuestion';

interface MessengerState {
  contact: MessengerContact;
  updateContact: (contact: MessengerContact) => void;
  addMessageToContact: (chatId: string, message: SaveQuestionResponse) => void;
  addChatToContact: (chat: MessengerChat) => void;
  activeChat: MessengerChat;
  setActiveChat: (chat: MessengerChat) => void;
  updateActiveChat: (partial: Partial<MessengerChat>) => void;
  activeScreen: Screen;
  updateScreen: (screen: Screen) => void;
  localDataLoading: boolean;
  setLocalDataLoading: (value: boolean) => void;
  messageLoading: boolean;
  setMessageLoading: (value: boolean) => void;
  inputDisabled: boolean;
  setInputDisabled: (value: boolean) => void;
  onlineAgents: { agentId: string; connectionId: string }[]; //agent ably connection id for online/offline status
  addOnlineAgent: (agentId: string, connectionId: string) => void;
  removeOnlineAgent: (agentId: string, connectionId: string) => void;
  resetOnlineAgents: () => void;
  aiFunctionLoadingStatus: AIFunctionAnimationStatus;
  setAiFunctionLoadingStatus: (status: AIFunctionAnimationStatus) => void;
  followUpQuestions: FollowUpQuestionSchema | null;
  addFollowUpQuestions: (questions: FollowUpQuestionSchema) => void;
  messengerLocation: string | null;
  setMessengerLocation: (location: string) => void;
  resetFollowUpQuestions: () => void;
  reset: () => void;
  markChatAsSeen: (chatId: string) => void;
}

type MessengerStoreInitialState = Pick<
  MessengerState,
  | 'contact'
  | 'activeChat'
  | 'activeScreen'
  | 'localDataLoading'
  | 'messageLoading'
  | 'inputDisabled'
  | 'onlineAgents'
  | 'aiFunctionLoadingStatus'
  | 'followUpQuestions'
  | 'messengerLocation'
>;

const messengerStoreInitialState: MessengerStoreInitialState = {
  contact: null,
  activeChat: null,
  activeScreen: 'list',
  localDataLoading: true,
  messageLoading: false,
  inputDisabled: false,
  onlineAgents: [],
  aiFunctionLoadingStatus: 'idle',
  followUpQuestions: null,
  messengerLocation: null,
};

export const messengerStore = create<MessengerState>((set) => ({
  ...messengerStoreInitialState,
  updateContact: (contact: MessengerContact) => set({ contact }),
  addMessageToContact: (chatId: string, message: SaveQuestionResponse) => {
    set((state) => {
      if (!state.contact?.chats || !Array.isArray(state.contact.chats)) {
        return state;
      }

      const updatedData = produce(state.contact, (draft) => {
        const chatIndex = draft.chats.findIndex((chat) => chat.id === chatId);
        if (chatIndex === -1) return draft;

        const chat = draft.chats[chatIndex];
        if (!chat.messages) {
          chat.messages = [];
        }
        chat.messages.unshift(message);

        draft.chats.splice(chatIndex, 1);
        draft.chats.unshift(chat);
      });

      return { contact: updatedData };
    });
  },
  addChatToContact: (chat: MessengerChat) => {
    set((state) => {
      if (!chat) return { contact: state.contact };

      const updatedData = produce(state.contact, (draft) => {
        if (!draft?.chats) {
          draft.chats = [chat];

          return draft;
        }

        const existingChatIndex = draft.chats.findIndex(
          (c) => c.id === chat.id,
        );

        if (existingChatIndex === -1) {
          draft.chats.unshift(chat);

          return draft;
        }

        const existingChat = draft.chats[existingChatIndex];
        if (chat.messages?.length > existingChat.messages?.length) {
          const newMessages = chat.messages.slice(existingChat.messages.length);
          existingChat.messages.unshift(...newMessages);

          draft.chats.splice(existingChatIndex, 1);
          draft.chats.unshift(existingChat);
        }
      });

      return { contact: updatedData };
    });
  },
  setActiveChat: (activeChat: MessengerChat) => set({ activeChat }),
  updateActiveChat: (partial: Partial<MessengerChat>) =>
    set((state) => ({
      activeChat: {
        ...state.activeChat,
        ...partial,
      },
    })),
  updateScreen: (activeScreen: Screen) => set({ activeScreen }),
  setLocalDataLoading: (value: boolean) => set({ localDataLoading: value }),
  setMessageLoading: (value: boolean) => set({ messageLoading: value }),
  setInputDisabled: (value: boolean) => set({ inputDisabled: value }),
  addOnlineAgent: (agentId: string, connectionId: string) =>
    set((state) => {
      const updatedData = produce(state.onlineAgents, (draft) => {
        draft?.push({ agentId, connectionId });
      });

      return { onlineAgents: uniq(updatedData) };
    }),
  removeOnlineAgent: (agentId: string, connectionId: string) =>
    set((state) => {
      const updatedData = produce(state.onlineAgents, (draft) => {
        const agentIndex = draft?.findIndex(
          (agent) =>
            agent.agentId === agentId && agent.connectionId === connectionId,
        );
        if (agentIndex === -1) return draft;

        draft?.splice(agentIndex, 1);
      });

      return { onlineAgents: updatedData };
    }),
  resetOnlineAgents: () => set({ onlineAgents: [] }),
  setAiFunctionLoadingStatus: (status: AIFunctionAnimationStatus) =>
    set({ aiFunctionLoadingStatus: status }),
  addFollowUpQuestions: (questions: FollowUpQuestionSchema) =>
    set({
      followUpQuestions: questions,
    }),
  setMessengerLocation: (location: string) =>
    set({ messengerLocation: location }),
  resetFollowUpQuestions: () => {
    set({ followUpQuestions: null });
  },
  reset: () => {
    set(messengerStoreInitialState);
  },
  markChatAsSeen: (chatId: string) => {
    set((state) => {
      if (!state.contact?.chats) {
        return state;
      }

      const updatedData = produce(state.contact, (draft) => {
        const chatIndex = draft.chats.findIndex((chat) => chat.id === chatId);
        if (chatIndex !== -1) {
          draft.chats[chatIndex].contactUnreadChats = [];
        }
      });

      return { contact: updatedData };
    });
  },
}));
