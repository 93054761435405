import { FC } from 'react';

import cn from 'classnames';

import { SvgProps } from './types';

const MessengerCloseIcon: FC<SvgProps> = ({
  className,
  width = '20',
  height = '20',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
      width={width}
      height={height}
      fill="none"
    >
      <path
        d="M7.99993 8.78092L11.2998 5.48111L12.2426 6.42392L7.99993 10.6666L3.75732 6.42392L4.70013 5.48111L7.99993 8.78092Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessengerCloseIcon;
