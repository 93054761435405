/* eslint-disable @next/next/no-img-element */
import { FC } from 'react';

import ArrowLeftIcon from '@heroicons/react/24/outline/ArrowLeftIcon';
import cn from 'classnames';
import { useColorPicker } from 'react-best-gradient-color-picker';
import Skeleton from 'react-loading-skeleton';

import { useGetWorkspaceAgents } from '@/hooks/useGetWorkspaceAgents';
import useTranslationStore from '@/store/translation';
import { getTextColor } from '@/utils/colors';

import LCALogo from '@public/assets/images/logo-messenger-header.png';
import LogoPng from '@public/assets/images/logo-white.png';

import Avatar from '../avatar.component';
import MessengerCloseIcon from '../icons/messenger-close-icon';
import { Agent } from './types';

type MessengerHeaderProps = {
  workspaceId: string;
  className?: string;
  logo?: string;
  agent?: Agent & { isOnline: boolean };
  aiAvatar?: string;
  backgroundColor?: string;
  humanAgentPreviewText?: string;
  handleClickBackButton?: () => void;
  handleClickCloseButton?: () => void;
};

const MessengerHeader: FC<MessengerHeaderProps> = ({
  workspaceId,
  className,
  logo,
  agent,
  aiAvatar,
  backgroundColor,
  humanAgentPreviewText,
  handleClickBackButton,
  handleClickCloseButton,
}) => {
  const { getValue } = useTranslationStore();

  const { data: agents, isLoading: isAgentsLoading } = useGetWorkspaceAgents({
    workspaceId,
  });

  const { valueToHex } = useColorPicker(backgroundColor || '#1C86FA', () => {});
  const hexString = valueToHex();
  const textColor = getTextColor(hexString);

  return (
    <div
      id="messenger-header"
      className={cn(
        'messenger-header top-0 flex w-full shrink-0 items-center px-[15px]',
        {
          ['flex-row-reverse']:
            !handleClickCloseButton && !!handleClickBackButton,
        },
        className,
      )}
      style={{ background: backgroundColor || '#1C86FA' }}
    >
      {!!handleClickBackButton ? (
        <button
          onClick={handleClickBackButton}
          data-tooltip-id="messenger-tooltip"
          data-tooltip-content={getValue('turnBackBtn')}
          data-tooltip-offset={15}
          data-testid="messenger-header-icon-button"
          className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[rgba(0,0,0,0.3)] text-white transition-transform duration-300 ease-in-out hover:scale-90"
        >
          <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      ) : null}

      {agent?.email ? (
        <div className="flex items-center">
          <Avatar
            size={28}
            email={agent.email}
            src={agent?.avatar}
            renderOnlineBadge={true}
            onlineStatus={agent?.isOnline}
          />
          <div className="ml-2 flex flex-col" style={{ color: textColor }}>
            <span className="text-xs font-medium leading-[14px]">
              {agent.name}
            </span>
            <span className="mt-[2px] text-[10px] leading-3 opacity-60">
              Joined the conversation
            </span>
          </div>
        </div>
      ) : (
        <div className="my-auto flex flex-col items-center justify-center">
          <div
            className="flex flex-col items-center justify-center"
            id="welcome-banner"
          >
            <img
              id="header-logo"
              className="h-full max-h-[28px] w-auto max-w-[122px] object-contain object-center"
              src={logo || LogoPng.src}
              alt="LiveChatAi.com Logo"
            />
            <div
              id="workspace-agents"
              className="messenger-header-agent-display mt-6 flex-col items-center justify-center"
            >
              {!!humanAgentPreviewText &&
              humanAgentPreviewText.trim() !== '' ? (
                <p
                  className="mb-[10px] text-center text-[20px] font-semibold leading-[30px]"
                  style={{ color: textColor }}
                >
                  {humanAgentPreviewText}
                </p>
              ) : null}
              <div className="relative flex h-[42px] flex-shrink-0 flex-row items-center justify-center">
                <Avatar
                  className="z-10 object-fill shadow-[0_1px_2px_0_rgb(0,0,0,0.5)]"
                  size={42}
                  src={aiAvatar || LCALogo.src}
                  renderOnlineBadge={false}
                />

                {isAgentsLoading ? (
                  <Skeleton className="-ml-3 h-[42px] w-[42px] rounded-full shadow-[0_1px_2px_0_rgb(0,0,0,0.5)]" />
                ) : (
                  <>
                    {agents && !!agents?.length
                      ? agents?.map((agent, index) => (
                          <div
                            key={agent.id}
                            className="-ml-3 flex h-[42px] w-[42px] flex-shrink-0 flex-row items-center justify-center rounded-full bg-white shadow-[0_1px_2px_0_rgb(0,0,0,0.5)]"
                            style={{ zIndex: agents.length - index }}
                          >
                            <Avatar
                              size={38}
                              email={agent.email}
                              src={agent?.avatar}
                              renderOnlineBadge={false}
                            />
                          </div>
                        ))
                      : null}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {!!handleClickCloseButton ? (
        <button
          onClick={handleClickCloseButton}
          data-tooltip-id="messenger-tooltip"
          data-tooltip-content={getValue('closeMessengerBtn')}
          data-tooltip-offset={15}
          data-testid="messenger-header-close-icon-button"
          className="messenger-header-close-position flex h-[28px] w-[28px] items-center justify-center rounded-full bg-[rgba(0,0,0,0.3)] text-white transition-transform duration-300 ease-in-out hover:scale-90"
        >
          <MessengerCloseIcon width="16px" height="16px" aria-hidden="true" />
        </button>
      ) : null}
    </div>
  );
};

export default MessengerHeader;
