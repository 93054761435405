import { GetKeys } from '@/types';

export type DayOptions =
  | 'weekdays'
  | 'weekends'
  | 'everyday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type Schedule = {
  status?: boolean;
  timezone?: string;
  dates?: Array<{
    days: DayOptions;
    startTime: string;
    endTime: string;
  }>;
};

export const GPTModels = {
  GPT4: 'gpt-4',
  GPT35Turbo: 'gpt-3.5-turbo',
  GPT4o: 'gpt-4o',
  GPT4oMINI: 'gpt-4o-mini',
  CLAUDE3SONNET: 'anthropic.claude-3-sonnet-20240229-v1:0',
  LLAMA3_1_70B: 'meta-llama/Meta-Llama-3.1-70B-Instruct',
  LLAMA3_1_405B: 'meta-llama/Meta-Llama-3.1-405B-Instruct',
  GEMINI_1_5_FLASH: 'gemini-1.5-flash',
  GEMINI_1_5_PRO: 'gemini-1.5-pro',
} as const;

export type GPTModel = (typeof GPTModels)[keyof typeof GPTModels];

export type GPTModels = {
  value: GPTModel;
  label: string;
};

export const MaxValueOptions = {
  SHORT: 250,
  MEDIUM: 500,
  LONG: 750,
} as const;

export type MaxValueOption =
  (typeof MaxValueOptions)[keyof typeof MaxValueOptions];

export type MaxValueOptions = {
  value: MaxValueOption;
  label: string;
};

export type CollectUserData = {
  status: boolean;
  after: number;
  email: {
    status: boolean;
    required: boolean;
  };
  phone: {
    status: boolean;
    required: boolean;
  };
  fullName: {
    status: boolean;
    required: boolean;
  };
};

export type SettingData = {
  basePrompt: string;
  gptModel: GPTModel;
  maxTokens: number;
  maxInputLength: number;
  temperature: number;
  aiName: string;
  company: string | null | undefined;
  isRestricted: boolean;
  isImageSharingEnabled: boolean;
  isLearnMoreEnabled: boolean;
  followUpQuestionEnabled: boolean;
  customDoNotKnowMessage: string | null | undefined;
  collectUserData: {
    status: boolean;
    after: number;
    email: {
      status: boolean;
      required: boolean;
    };
    phone: {
      status: boolean;
      required: boolean;
      countryCode: string | null;
    };
    fullName: {
      status: boolean;
      required: boolean;
    };
  };
  limiter: {
    alertText: string;
    limit: number;
    window: number;
  };
  schedule: Schedule;
};

export type SettingKeys = GetKeys<SettingData>;
