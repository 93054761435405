import { useCallback, useEffect } from 'react';

import { Chatbot } from '@prisma/client';

import { getTranslationFields } from '@/app/actions/getTranslationFields';
import { TranslationsFields } from '@/constants/translation';
import useTranslationStore from '@/store/translation';
import { getBrowserLanguage } from '@/utils/translations';

type UseTranslationUpdateProps = {
  chatbot: {
    id: Chatbot['id'];
    language: Chatbot['language'];
    isAutoTranslationEnabled: Chatbot['isAutoTranslationEnabled'];
    supportedLanguages: Chatbot['supportedLanguages'];
  };
  fields?: TranslationsFields;
  supportedLanguagesFields?: { [key: string]: TranslationsFields };
  shouldFetchFields?: boolean;
  shouldReset?: boolean;
};

type TranslationUpdateReturn = {
  getTranslationAndUpdate: () => Promise<void>;
};

export function useTranslationUpdate({
  chatbot,
  fields,
  supportedLanguagesFields,
  shouldFetchFields = false,
  shouldReset = true,
}: UseTranslationUpdateProps): TranslationUpdateReturn {
  const getTranslationAndUpdate = useCallback(async () => {
    if (!chatbot?.language) return;

    if (!shouldFetchFields && !fields) {
      console.warn('No fields provided and shouldFetchFields is false');
      return;
    }

    if (shouldFetchFields && !chatbot?.id) {
      console.warn('No chatbot id provided for fetching fields');
      return;
    }

    let defaultLanguageFields = fields;
    let translationFields = supportedLanguagesFields;

    if (shouldFetchFields) {
      const fetchedFields = await getTranslationFields({
        chatbotId: chatbot.id!,
        defaultLanguage: chatbot.language,
        supportedLanguages: chatbot.supportedLanguages,
      });

      defaultLanguageFields = fetchedFields.defaultLanguageFields;
      translationFields = fetchedFields.supportedLanguagesFields;

      Object.keys(fetchedFields).forEach((key) => {
        if (key !== chatbot.language) {
          translationFields[key] = fetchedFields[key];
        }
      });
    }

    useTranslationStore
      .getState()
      .updateTranslationFields(defaultLanguageFields, chatbot.language);

    if (!chatbot?.isAutoTranslationEnabled) return;
    if (!chatbot?.supportedLanguages?.length) return;

    const browserLanguage = getBrowserLanguage();
    if (!browserLanguage) return;

    const activeSupportedLanguage = chatbot.supportedLanguages.find(
      (language) => language === browserLanguage,
    );
    if (!activeSupportedLanguage) return;

    const activeSupportedLanguageFields =
      translationFields?.[activeSupportedLanguage];
    if (!activeSupportedLanguageFields) return;

    // Update supported language fields
    useTranslationStore
      .getState()
      .updateTranslationFields(
        activeSupportedLanguageFields,
        activeSupportedLanguage,
      );
  }, [
    chatbot?.id,
    chatbot?.language,
    chatbot?.isAutoTranslationEnabled,
    chatbot?.supportedLanguages,
    fields,
    supportedLanguagesFields,
    shouldFetchFields,
  ]);

  useEffect(() => {
    getTranslationAndUpdate();

    return () => {
      if (shouldReset) {
        useTranslationStore.getState().resetTranslationFields();
      }
    };
  }, [getTranslationAndUpdate, chatbot?.id, shouldReset]);

  return { getTranslationAndUpdate };
}
