import { create } from 'zustand';

import type { Reply } from '@/components/messenger/types';

interface ReplyState {
  reply: Reply;
  clearReply: () => void;
  updateReply: (partial: Partial<Reply>) => void;
  addToReplyMessage: (message: string) => void;
}

export const replyStore = create<ReplyState>((set) => ({
  reply: { message: '', source: null, customElement: null },
  clearReply: () =>
    set({ reply: { message: '', source: null, customElement: null } }),
  updateReply: (reply: Reply) =>
    set((state) => ({ reply: { ...state.reply, ...reply } })),
  addToReplyMessage: async (message: string) =>
    set((state) => ({
      reply: { ...state.reply, message: state.reply.message + message },
    })),
}));
