import { useEffect, useRef } from 'react';

import { Sender } from '@prisma/client';

import { ContactChannelMessageData } from '@/app/api/aibot/reply/ably';
import type { SaveQuestionResponse } from '@/helpers/saveQuestion';
import { messengerStore } from '@/store/messenger';
import { connectChannel, disconnectChannel } from '@/utils/useChannel';
import {
  PresenceMessage,
  subscribeChannelPresence,
  unsubscribeChannelPresence,
} from '@/utils/usePresenceChannel';

const checkAgentInContact = async (contactId: string) => {
  try {
    const response = await fetch(`/api/aibot/check-agent/${contactId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result: {
      isAgentConnected: boolean;
    } = await response.json();
    return result.isAgentConnected;
  } catch {
    return null;
  }
};

export default function useContactChannelListener({
  chatbotId,
  handleMessageCallback,
  handleEnterCallback,
  handleLeaveCallback,
}: {
  chatbotId: string;
  handleMessageCallback: (
    chatId: string,
    message: SaveQuestionResponse,
  ) => void;
  handleEnterCallback: (connectionId: string, type: Sender, id: string) => void;
  handleLeaveCallback: (connectionId: string, type: Sender, id: string) => void;
}) {
  const contactId = messengerStore((state) => state.contact?.id);
  const intervalRef = useRef(null);

  useEffect(() => {
    const checkExistAnyAgentInContact = async () => {
      if (!contactId) return;

      const isExistAnyAgent = await checkAgentInContact(contactId);
      if (isExistAnyAgent) {
        connectChannel({
          channelName: `contact-${contactId}`,
          member: { id: contactId, type: 'USER' },
          callbackOnMessage: ({ chatId, message }: ContactChannelMessageData) =>
            handleMessageCallback(chatId, message),
          callbackOnChatStatus: ({ chatId, isResolved }) => {
            if (chatId === messengerStore.getState().activeChat?.id) {
              messengerStore.getState().updateActiveChat({ isResolved });
            }
          },
          isHistoryEnabled: true,
        });

        subscribeChannelPresence(
          `chatbot-${chatbotId}`,
          (member: PresenceMessage) => {
            handleEnterCallback(
              member.connectionId,
              member.data?.type,
              member.data?.id,
            );
          },
          (member: PresenceMessage) => {
            handleLeaveCallback(
              member.connectionId,
              member?.data?.type,
              member?.data?.id,
            );
          },
        );

        clearInterval(intervalRef.current);
      }
    };

    intervalRef.current = setInterval(() => {
      checkExistAnyAgentInContact();
    }, 30000);

    checkExistAnyAgentInContact();

    return () => {
      disconnectChannel();
      unsubscribeChannelPresence();
      messengerStore.getState().resetOnlineAgents();
      clearInterval(intervalRef.current);
    };
  }, [chatbotId, contactId]);
}
