import React, { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { MessageType } from '@prisma/client';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import Button from '@/components/button.component';
import MicrophoneIcon from '@/components/icons/microphone-icon.component';
import PauseIcon from '@/components/icons/pause-icon.component';
import SecurityCheckIcon from '@/components/icons/security-check-icon.component';
import Input from '@/components/input.component';
import { useMessengerContext } from '@/context/messengerContext';
import useAudioTranscription from '@/hooks/useAudioTranscription';
import { messengerStore } from '@/store/messenger';
import useTranslationStore from '@/store/translation';

import AudioVisualization from '../audio-visualization.component';
import SendNextBlackIcon from '../icons/send-next-black-icon';
import SendNextIcon from '../icons/send-next-icon';
import WarningToast from '../toasts/warning-toast.component';
import MessageImageInput from './message-image-input.component';

export const schema = yup.object().shape({
  message: yup.string().trim().required('This field is required!'),
});

type FormData = {
  message: string;
};

type MessageInputProps = {
  loading?: boolean;
  disabled?: boolean;
  showImageInput?: boolean;
  messageLimit?: number;
  handleSubmit?: (value: string, type: MessageType) => void;
};

const MessageInput: FC<MessageInputProps> = ({
  loading,
  disabled,
  showImageInput,
  messageLimit,
  handleSubmit,
}) => {
  const { chatbot: { id: chatbotId } = {} } = useMessengerContext();

  const chatId = messengerStore((state) => state.activeChat?.id);

  const { getValue } = useTranslationStore();

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const { isRecording, isSpeaking, audioLevels, toggleRecording } =
    useAudioTranscription(watch('message'), (updateFunction) => {
      setValue('message', updateFunction(watch('message')), {
        shouldValidate: true,
      });
    });

  const onSubmit = handleFormSubmit((data: FormData) => {
    if (disabled) return;

    if (data.message?.length > messageLimit) {
      toast(
        <WarningToast
          type="embed"
          detail={`Question length cannot be more than ${messageLimit} characters`}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );
      return;
    }

    if (handleSubmit) handleSubmit(data.message, 'TEXT');
    reset();
  });

  const handleSendImageMessage = (url: string) => {
    if (disabled) return;

    if (handleSubmit) handleSubmit(url, 'IMAGE');
  };

  const handleRecording = () => {
    if (disabled) return;

    toggleRecording();
  };

  return (
    <>
      {(isRecording || isSpeaking) && (
        <div className="flex items-center justify-between border-t border-[#EAEAEA] bg-green-50 px-4 py-2">
          <span className="text-[9px] font-medium text-green-700">
            {getValue('speaking')}
          </span>
          <AudioVisualization audioData={audioLevels} />
          <SecurityCheckIcon width="20" height="20" />
        </div>
      )}

      <form
        data-testid="message-input-form"
        id="message-input-wrapper"
        className={cn(
          'flex flex-col border-b border-t border-[#EAEAEA] bg-white px-[15px] py-[20px]',
          {
            ['cursor-not-allowed']: disabled,
          },
        )}
        onSubmit={onSubmit}
      >
        <div className="flex items-center">
          <Input
            className="w-full"
            variant="messenger"
            placeholder={getValue('sendPlaceholder')}
            aria-label="chat input"
            data-testid="message-input"
            disabled={disabled}
            autoComplete="off"
            required
            {...register('message')}
            ref={(e) => {
              register('message').ref(e);
            }}
          />
          <div className="ml-2 flex items-center justify-center">
            <Button
              size="sm"
              type="button"
              variant="unstyled"
              onClick={handleRecording}
              isDisabled={disabled}
              className="mr-2 h-[20px] w-[20px] text-[#777777] hover:text-black"
            >
              {isRecording ? <PauseIcon /> : <MicrophoneIcon />}
            </Button>
            {showImageInput ? (
              <MessageImageInput
                className="mr-2 mt-[1px] h-[18px] w-[18px]"
                name="messenger-image-input"
                chatbotId={chatbotId}
                chatId={chatId}
                disabled={disabled}
                handleUpload={handleSendImageMessage}
              />
            ) : null}
            {!isRecording ? (
              <Button
                size="sm"
                type="submit"
                variant="unstyled"
                isLoading={loading}
                isDisabled={disabled || loading || isRecording}
                data-testid="message-input-submit"
                className="h-[18px] w-[18px] text-[#777777] hover:text-black"
              >
                {watch('message')?.length > 0 ? (
                  <SendNextBlackIcon />
                ) : (
                  <SendNextIcon />
                )}
              </Button>
            ) : null}
          </div>
        </div>
      </form>
    </>
  );
};

export default MessageInput;
