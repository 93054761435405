export type Language = 'ENGLISH' | 'SPANISH' | 'GERMAN' | 'TURKISH' | 'FRENCH';

export type LanguageOption = {
  label: string;
  value: Language;
};

export type TranslationsFields = {
  newMessageToast: string;
  defaultError: string;
  userDataSuccess: string;
  newMessageBtn: string;
  you: string;
  sendPlaceholder: string;
  learnMore: string;
  resolutionQuestion: string;
  helped: string;
  notHelped: string;
  collectUserDataLabel: string;
  emailPlaceholder: string;
  phonePlaceholder: string;
  fullNamePlaceholder: string;
  dummyAiMessage: string;
  dummyUserMessage: string;
  supportRequestedTitle: string;
  supportRequestedDescription: string;
  joinText: string;
  aiIsWriting?: string;
  speaking: string;
  turnBackBtn: string;
  closeMessengerBtn: string;
  newConversationBtn: string;
  conversationClosed: string;
};

const DEFAULT_ENGLISH: TranslationsFields = {
  newMessageToast: 'New message!',
  defaultError: 'Something went wrong',
  userDataSuccess: 'User data successfully saved',
  newMessageBtn: 'New message',
  you: 'You',
  sendPlaceholder: 'Send message',
  learnMore: 'Learn More',
  resolutionQuestion: 'This response helpful to you?',
  helped: 'That helped 👍',
  notHelped: "That didn't help 👎",
  collectUserDataLabel: 'Fill out the form to get updates.',
  emailPlaceholder: 'Email',
  phonePlaceholder: 'Phone',
  fullNamePlaceholder: 'Full Name',
  dummyAiMessage: 'AI message',
  dummyUserMessage: 'visitor message',
  supportRequestedTitle: 'Human Support Requested 🎉',
  supportRequestedDescription:
    'We will send your request to all available agents, you will receive answers as soon as possible from here.',
  joinText: 'is joined the conversation',
  aiIsWriting: 'AI is writing',
  speaking: 'Speaking',
  turnBackBtn: 'Turn back',
  closeMessengerBtn: 'Close',
  newConversationBtn: 'New conversation',
  conversationClosed: 'The conversation has been closed by the agent.',
};

const DEFAULT_SPANISH: TranslationsFields = {
  newMessageToast: 'Nuevo mensaje!',
  defaultError: 'Algo salió mal',
  userDataSuccess: 'Datos de usuario guardados correctamente',
  newMessageBtn: 'Nuevo mensaje',
  you: 'Usted',
  sendPlaceholder: 'Enviar mensaje',
  learnMore: 'Más información',
  resolutionQuestion: 'Le ha sido útil esta respuesta?',
  helped: 'Eso ayudó 👍',
  notHelped: 'Eso no ayudó 👎',
  collectUserDataLabel: 'Rellene el formulario para recibir actualizaciones.',
  emailPlaceholder: 'Correo electrónico',
  phonePlaceholder: 'Teléfono',
  fullNamePlaceholder: 'Nombre completo',
  dummyAiMessage: 'Mensaje AI ficticio',
  dummyUserMessage: 'Mensaje de usuario ficticio',
  supportRequestedTitle: 'Soporte humano solicitado 🎉',
  supportRequestedDescription:
    'Enviaremos su solicitud a todos los agentes disponibles, recibirá respuestas lo antes posible desde aquí.',
  joinText: 'se unió a la conversación',
  aiIsWriting: 'AI está escribiendo',
  speaking: 'Hablando',
  turnBackBtn: 'Volver',
  closeMessengerBtn: 'Cerrar',
  newConversationBtn: 'Nueva conversación',
  conversationClosed: 'La conversación ha sido cerrada por el agente.',
};

const DEFAULT_GERMAN: TranslationsFields = {
  newMessageToast: 'Neue Nachricht!',
  defaultError: 'Etwas ist schief gelaufen',
  userDataSuccess: 'Daten des Benutzers erfolgreich gespeichert',
  newMessageBtn: 'Neue Nachricht',
  you: 'Sie',
  sendPlaceholder: 'Nachricht senden',
  learnMore: 'Mehr erfahren',
  resolutionQuestion: 'Ist diese Antwort hilfreich für Sie?',
  helped: 'Das hat geholfen 👍',
  notHelped: 'Das hat nicht geholfen 👎',
  collectUserDataLabel: 'Füllen Sie das Formular aus, um Updates zu erhalten.',
  emailPlaceholder: 'E-Mail',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Vollständiger Name',
  dummyAiMessage: 'Dummy AI-Nachricht',
  dummyUserMessage: 'Dummy-Benutzernachricht',
  supportRequestedTitle: 'Menschliche Unterstützung angefordert 🎉',
  supportRequestedDescription:
    'Wir werden Ihre Anfrage an alle verfügbaren Agenten senden, Sie erhalten so schnell wie möglich Antworten von hier.',
  joinText: 'hat sich dem Gespräch angeschlossen',
  aiIsWriting: 'AI schreibt',
  speaking: 'Sprechen',
  turnBackBtn: 'Zurück',
  closeMessengerBtn: 'Schließen',
  newConversationBtn: 'Neues Gespräch',
  conversationClosed: 'Das Gespräch wurde vom Agenten geschlossen.',
};

const DEFAULT_TURKISH: TranslationsFields = {
  newMessageToast: 'Yeni Mesaj!',
  defaultError: 'Bir şeyler ters gitti.',
  userDataSuccess: 'Kullanıcı verileri başarıyla kaydedildi.',
  newMessageBtn: 'Yeni Mesaj',
  you: 'Sen',
  sendPlaceholder: 'Mesaj Gönder',
  learnMore: 'Daha fazla bilgi',
  resolutionQuestion: 'Bu yanıt size yardımcı oldu mu?',
  helped: 'Yanıt yardımcı oldu 👍',
  notHelped: 'Yanıt yardımcı olmadı 👎',
  collectUserDataLabel: 'Güncellemeleri almak için formu doldurun.',
  emailPlaceholder: 'E-posta',
  phonePlaceholder: 'Telefon',
  fullNamePlaceholder: 'Tam Adınız',
  dummyAiMessage: 'Demo yapay zeka mesajı',
  dummyUserMessage: 'Demo kullanıcı mesajı',
  supportRequestedTitle: 'Destek Talep Edildi 🎉',
  supportRequestedDescription:
    'Talebinizi tüm mevcut temsilcilere göndereceğiz, buradan en kısa sürede cevaplar alacaksınız.',
  joinText: 'sohbete katıldı',
  aiIsWriting: 'AI yazıyor',
  speaking: 'Konuşuyor',
  turnBackBtn: 'Geri',
  closeMessengerBtn: 'Kapat',
  newConversationBtn: 'Yeni sohbet',
  conversationClosed: 'Temsilci, görüşmeyi sonlandırdı.',
};

const DEFAULT_FRENCH: TranslationsFields = {
  newMessageToast: 'Nouveau message!',
  defaultError: "Quelque chose s'est mal passé",
  userDataSuccess: 'Données utilisateur enregistrées avec succès',
  newMessageBtn: 'Nouveau message',
  you: 'Vous',
  sendPlaceholder: 'Envoyer un message',
  learnMore: 'En savoir plus',
  resolutionQuestion: 'Cette réponse vous a-t-elle aidé ?',
  helped: 'Cela a aidé 👍',
  notHelped: "Cela n'a pas aidé 👎",
  collectUserDataLabel:
    'Remplissez le formulaire pour recevoir des mises à jour.',
  emailPlaceholder: 'E-mail',
  phonePlaceholder: 'Téléphone',
  fullNamePlaceholder: 'Nom complet',
  dummyAiMessage: 'Message IA',
  dummyUserMessage: 'Message visiteur',
  supportRequestedTitle: 'Support humain demandé 🎉',
  supportRequestedDescription:
    'Nous enverrons votre demande à tous les agents disponibles, vous recevrez des réponses dès que possible ici.',
  joinText: 'a rejoint la conversation',
  aiIsWriting: "L'IA écrit",
  speaking: 'Parle',
  turnBackBtn: 'Retour',
  closeMessengerBtn: 'Fermer',
  newConversationBtn: 'Nouvelle conversation',
  conversationClosed: "La conversation a été fermée par l'agent.",
};

const DEFAULT_FIELDS: {
  [key in Language]: TranslationsFields;
} = {
  ENGLISH: DEFAULT_ENGLISH,
  SPANISH: DEFAULT_SPANISH,
  GERMAN: DEFAULT_GERMAN,
  TURKISH: DEFAULT_TURKISH,
  FRENCH: DEFAULT_FRENCH,
};

const LANGUAGE_OPTIONS: LanguageOption[] = [
  { label: 'Turkish', value: 'TURKISH' },
  { label: 'English', value: 'ENGLISH' },
  { label: 'German', value: 'GERMAN' },
  { label: 'French', value: 'FRENCH' },
  { label: 'Spanish', value: 'SPANISH' },
];

const BROWSER_LANGUAGE_TO_LANGUAGE: {
  [key in Language]: string[];
} = {
  ENGLISH: ['en', 'en-US', 'en-GB'],
  SPANISH: ['es', 'es-ES', 'es-419'],
  GERMAN: ['de', 'de-DE'],
  TURKISH: ['tr', 'tr-TR'],
  FRENCH: ['fr', 'fr-FR', 'fr-CA'],
};

export {
  DEFAULT_ENGLISH,
  DEFAULT_SPANISH,
  DEFAULT_GERMAN,
  DEFAULT_TURKISH,
  DEFAULT_FRENCH,
  DEFAULT_FIELDS,
  LANGUAGE_OPTIONS,
  BROWSER_LANGUAGE_TO_LANGUAGE,
};
