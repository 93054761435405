import { InvitationStatus, WorkspaceRole } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';

import { getWorkspaceUsersById } from '@/app/actions/getWorkspaceUsers';

export type WorkspaceUsersResponse =
  | {
      id: string;
      email: string;
      name: string;
      avatar: string;
      role: WorkspaceRole;
      invitationStatus: InvitationStatus;
    }[]
  | null;

type GetWorkspaceUsersParams = {
  workspaceId: string;
  onlyActiveUsers?: boolean;
};

export function useWorkspaceUsers({
  workspaceId,
  onlyActiveUsers = false,
}: GetWorkspaceUsersParams) {
  return useQuery<WorkspaceUsersResponse>({
    queryKey: ['workspace-users', workspaceId],
    queryFn: () => getWorkspaceUsersById({ workspaceId, onlyActiveUsers }),
    enabled: !!workspaceId,
  });
}
