'use client';

import { FC, memo, useEffect, useMemo } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import DOMPurify from 'isomorphic-dompurify';
import { useSearchParams } from 'next/navigation';

import { CustomizeData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';
import MessengerHeader from '@/components/messenger/messenger-header.component';
import { useMessengerContext } from '@/context/messengerContext';
import { handleIdentifyEvent } from '@/embed/events/identifyEvent';
import { processNestedCss } from '@/helpers/processNestedCss';
import { messagesStore } from '@/store/messages';
import { messengerStore } from '@/store/messenger';
import { getChatbotCustomize } from '@/utils/customize';
import LocalStorage from '@/utils/local-storage';

import LoadingDots from '../loading/loading-dots.components';
import ConversationList from './conversation-list.component';
import Messenger, { MessengerProps } from './messenger.component';

dayjs.extend(relativeTime);

type MessengerWrapperProps = MessengerProps & {
  headerClassName?: string;
  isFullPageView?: boolean;
};

const MemorizedConversationList = memo(ConversationList);
const MemorizedMessenger = memo(Messenger);

const MessengerWrapper: FC<MessengerWrapperProps> = ({
  hideBottomActions,
  headerClassName,
  bodyClassName,
  isFullPageView,
}) => {
  const searchParams = useSearchParams();
  const urlIdentity = searchParams.get('identity');

  const identity = useMemo(() => {
    if (!urlIdentity) return null;

    try {
      const parsed = JSON.parse(urlIdentity);
      if (!parsed.distinctId) return null;
      return JSON.parse(urlIdentity);
    } catch (e) {
      return null;
    }
  }, [urlIdentity]);

  const {
    isEmbed, //flag for embed.js
    chatbot,
    chatType,
    isFullHeight,
    handleGetContact,
    handleCloseMessenger,
    updateActiveChat,
    updateConverationListView,
  } = useMessengerContext();

  const [
    localDataLoading,
    contact,
    activeChat,
    activeScreen,
    onlineAgents,
    updateScreen,
    addChatToContact,
  ] = messengerStore((state) => [
    state.localDataLoading,
    state.contact,
    state.activeChat,
    state.activeScreen,
    state.onlineAgents,
    state.updateScreen,
    state.addChatToContact,
  ]);

  const showBackButton = useMemo(() => {
    if (activeScreen !== 'messenger') return false;

    return (
      !!activeChat || (contact && contact?.chats && contact?.chats?.length > 0)
    );
  }, [activeScreen, activeChat, contact]);

  const isInlineChat = chatType === 'INLINECHAT';
  const isLiveChatEnabled = chatbot.isLiveChatEnabled;

  const customize = getChatbotCustomize(chatbot?.customize as CustomizeData);

  const humanAgentPreviewText = customize.theme.humanAgentPreviewText;
  const aiAvatar = customize?.theme?.aiAvatar;

  // if the messenger is embedded with embed.js and the plan has custom css, we need to add custom css to the messenger
  const customCSS = chatbot?.workspace.plan.hasCustomCSS
    ? processNestedCss(
        `
    ${DOMPurify.sanitize(customize?.customCSS)} 
  
    div#livechatai-messenger div#messenger-bottom div#messenger-footer { display:block !important; opacity: 1 !important; background-color: rgb(245 245 245) !important; height: 22px !important; max-height: 22px !important; visibility: visible !important;}
    div#livechatai-messenger div#messenger-bottom div#messenger-footer p { display:block !important; opacity: 1 !important; color: rgb(119 119 119) !important; font-size: 8px !important; visibility: visible !important;}`,
      )
    : null;

  const chatAgent = useMemo(() => {
    if (!activeChat || !activeChat?.messages) return null;

    const activeChatLastMessage =
      activeChat?.messages[activeChat?.messages?.length - 1];
    const activeChatAgent = activeChatLastMessage?.agent;

    return activeChatAgent;
  }, [activeChat]);

  const isAgentOnline = useMemo(() => {
    if (!chatAgent) return false;

    const index = onlineAgents.findIndex(
      (agent) => agent.agentId === chatAgent?.id,
    );

    return index !== -1;
  }, [chatAgent, onlineAgents]);

  const messengerHeaderColor = customize?.theme?.color?.header;
  const fullPageHeaderColor =
    customize?.fullPage?.theme?.headerColor || messengerHeaderColor;

  useEffect(() => {
    if (!chatbot?.id) {
      return;
    }

    handleIdentifyEvent(chatbot.id, identity)
      .then(async () => {
        const localContactId = LocalStorage.get(`live-chat-ai-${chatbot.id}`);

        const stateContactId = messengerStore.getState().contact?.id;
        if (localContactId && localContactId !== stateContactId) {
          await handleGetContact(localContactId);
        }

        const stateActiveChat = messengerStore.getState().activeChat;
        if (!localContactId && !stateActiveChat) {
          messengerStore.getState().updateScreen('messenger');
        }
      })
      .catch(() => {
        const stateActiveChat = messengerStore.getState().activeChat;
        if (!stateActiveChat) {
          messengerStore.getState().updateScreen('messenger');
        }
      })
      .finally(() => {
        messengerStore.getState().setLocalDataLoading(false);
      });
  }, [handleGetContact, chatbot?.id, identity]);

  useEffect(() => {
    if (localDataLoading) return;

    if (!isInlineChat || !document || !isLiveChatEnabled) {
      updateConverationListView({ view: 'minimal' });
      return;
    }

    if (activeScreen === 'messenger') {
      updateConverationListView({ view: 'minimal' });

      return;
    }

    updateConverationListView({ view: 'banner' });

    const conversationListBody = document.getElementById(
      'conversation-list-body',
    );
    if (!conversationListBody) {
      updateConverationListView({ view: 'minimal' });
      return;
    }

    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement;
      if (!target) return;

      const scrollTop = target.scrollTop || 0;
      const height = 198 - scrollTop / 3;

      if (height > 80) {
        updateConverationListView({ view: 'dynamic', scrollTop });
      } else {
        updateConverationListView({ view: 'minimal' });
        conversationListBody.removeEventListener('scroll', handleScroll);
      }
    };

    conversationListBody.addEventListener('scroll', handleScroll);

    return () => {
      updateConverationListView({ view: 'minimal' });
      conversationListBody.removeEventListener('scroll', handleScroll);
    };
  }, [
    updateConverationListView,
    localDataLoading,
    activeScreen,
    isInlineChat,
    isLiveChatEnabled,
  ]);

  const handleClickBackButton = () => {
    const messagesState = messagesStore.getState().messages;
    const messages = [...(messagesState || [])].sort(
      (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    );
    const lastMessage = messages?.[messages?.length - 1];

    addChatToContact({
      id: activeChat.id,
      messages: messages,
      createdAt: activeChat.createdAt || lastMessage?.createdAt || new Date(),
      isAgent: activeChat.isAgent || false,
      isAIHelped: activeChat.isAIHelped || false,
      isMuted: activeChat.isMuted || false,
      isResolved: activeChat.isResolved || false,
      isStarred: activeChat.isStarred || false,
      resolvedBy: activeChat.resolvedBy || null,
      chatResolutionLogs: activeChat.chatResolutionLogs || [],
      agentUnreadChats: activeChat.agentUnreadChats || [],
      contactUnreadChats: activeChat.contactUnreadChats || [],
    });
    updateActiveChat(null);
    updateScreen('list');
  };

  return (
    <>
      <div
        id="livechatai-messenger"
        style={{ background: messengerHeaderColor || '#1C86FA' }}
        className={cn('livechatai-messenger-grid relative grid h-full w-full', {
          ['ml-auto']: customize?.theme?.align === 'right',
          ['mr-auto']: customize?.theme?.align === 'left',
          ['max-h-screen min-h-screen']: isFullHeight,
        })}
      >
        <MessengerHeader
          className={headerClassName}
          workspaceId={chatbot?.workspaceId}
          logo={customize?.theme?.headerLogo}
          agent={{ ...chatAgent, isOnline: isAgentOnline }}
          aiAvatar={aiAvatar}
          backgroundColor={
            isFullPageView ? fullPageHeaderColor : messengerHeaderColor
          }
          humanAgentPreviewText={humanAgentPreviewText}
          {...(showBackButton
            ? {
                handleClickBackButton: handleClickBackButton,
              }
            : {})}
          {...(isEmbed
            ? {
                handleClickCloseButton: () => {
                  handleCloseMessenger();
                },
              }
            : {})}
        />

        <MessengerBody
          bodyClassName={bodyClassName}
          hideBottomActions={hideBottomActions}
          headerColor={messengerHeaderColor}
        />
      </div>

      {customCSS ? (
        <style
          id="live-chat-ai-custom-css-style"
          className="live-chat-ai-custom-css-style"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(customCSS),
          }}
        />
      ) : null}
    </>
  );
};

type MessengerBodyProps = {
  bodyClassName?: string;
  hideBottomActions?: boolean;
  headerColor?: string;
};
const MessengerBody: FC<MessengerBodyProps> = ({
  headerColor,
  bodyClassName,
  hideBottomActions,
}) => {
  const [activeScreen, loading] = messengerStore((state) => [
    state.activeScreen,
    state.localDataLoading,
  ]);

  if (loading) {
    return (
      <div className="flex h-full w-full flex-grow items-center justify-center bg-white">
        <LoadingDots size={42} fill={headerColor || '#1C86FA'} />
      </div>
    );
  }

  if (activeScreen === 'messenger') {
    return (
      <MemorizedMessenger
        bodyClassName={bodyClassName}
        hideBottomActions={hideBottomActions}
      />
    );
  }

  if (activeScreen === 'list') {
    return <MemorizedConversationList />;
  }

  return null;
};

export default MessengerWrapper;
