'use client';

import { FC, useMemo } from 'react';

import { Sender } from '@prisma/client';
import cn from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/tr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useColorPicker } from 'react-best-gradient-color-picker';

import useTranslationStore from '@/store/translation';
import { AllElements } from '@/types/elements/type';
import { addAlpha, getTextColor } from '@/utils/colors';
import { getLanguageCode } from '@/utils/translations';

import Avatar from '../avatar.component';
import AIActionDebug from './ai-action-debug.component';
import CollectUserData from './collect-user-data.component';
import CustomElementRenderer from './custom-element';
import MessageBox, { MessageBoxProps } from './message-box.component';
import type { Agent, MessageFunctionLog } from './types';

dayjs.extend(relativeTime);

export type MessageProps = Omit<MessageBoxProps, 'style'> & {
  time: Date;
  sender: Sender;
  agent: Agent;
  showAvatar?: boolean;
  showUserDataForm?: boolean;
  aiAvatar?: string;
  aiAgentName?: string;
  backgroundColor: string;
  emailRequired?: boolean;
  phoneRequired?: boolean;
  fullNameRequired?: boolean;
  handleSubmitUserData?: (value: {
    email?: string;
    phone?: string;
    fullName?: string;
  }) => void;
  collectUserData?: {
    fullName: { status: boolean; required: boolean };
    phone: { status: boolean; required: boolean; countryCode?: string | null };
    email: { status: boolean; required: boolean };
    status: boolean;
    after: number;
  };
  showHumanSupportButton?: boolean;
  isQuotaLimitReached?: boolean;
  aIFunctionLogs?: MessageFunctionLog;
  aiTyping?: boolean;
  customElement?: AllElements;
};

const Message: FC<MessageProps> = ({
  messageId,
  chatbotId,
  activeChatId,
  chatbotWorkspaceId,
  showAvatar = true,
  children,
  position,
  variant,
  sender,
  source,
  agent,
  time,
  type,
  showOnlyWebsiteSource = true,
  showResolutionForm = false,
  showAddQAButton = false,
  showUserDataForm = false,
  qualityBadge,
  backgroundColor,
  aiAgentName = 'AI Agent',
  aiAvatar,
  handleSubmitUserData,
  collectUserData,
  showHumanSupportButton = false,
  isQuotaLimitReached,
  aIFunctionLogs,
  aiTyping = false,
  customElement,
}) => {
  const { valueToHex, isGradient } = useColorPicker(backgroundColor, () => {});
  const hexString = valueToHex();
  const textColor = getTextColor(hexString);
  const borderColor = isGradient ? '#E5EEF3' : addAlpha(textColor, 0.1);
  const codeColor = isGradient
    ? 'rgba(229, 231, 235, 0.2)'
    : addAlpha(textColor, 0.2);

  const { language } = useTranslationStore();
  const languageCode = getLanguageCode(language);

  const senderName = useMemo(() => {
    if (sender === 'USER') return null;
    if (sender === 'AI') return aiAgentName;

    if (sender === 'AGENT' && agent?.name) return agent.name;

    return null;
  }, [aiAgentName, sender, agent]);

  const avatar = useMemo(() => {
    if (sender === Sender.AI) return aiAvatar;

    return agent?.avatar;
  }, [aiAvatar, sender, agent]);

  const isAgentOrAI = sender === Sender.AI || sender === Sender.AGENT;

  const renderCustomElement = customElement && customElement.length > 0;

  const renderCollectDataForm =
    showUserDataForm && sender === Sender.AI && !renderCustomElement;

  return (
    <div
      id="message-box-container"
      className="mb-[15px] inline-flex w-full min-w-full flex-col"
    >
      <div
        id="message-box"
        className={cn('inline-flex max-w-full flex-col sm:max-w-[95%]', {
          ['mr-auto min-w-full sm:min-w-[35%]']: position === 'left',
          ['ml-auto']: position === 'right',
        })}
        style={
          { '--message-box-code-background': codeColor } as React.CSSProperties
        }
      >
        <div
          className={cn('inline-flex w-fit max-w-full', {
            ['w-full']: position === 'left',
            ['flex-row-reverse']: position === 'right',
          })}
        >
          {isAgentOrAI ? (
            <div
              className={cn('mt-auto h-[28px] w-[28px]', {
                ['mr-[10px]']: position === 'left',
                ['ml-[10px]']: position === 'right',
              })}
            >
              {showAvatar ? (
                <Avatar
                  src={avatar}
                  size={28}
                  email={agent?.email || agent?.name}
                  defaultAIAvatar={sender === Sender.AI && !aiAvatar}
                />
              ) : null}
            </div>
          ) : null}

          <div
            className={cn({
              'w-fit max-w-full': !isAgentOrAI,
              'w-full max-w-[calc(100%-38px)]': isAgentOrAI,
            })}
          >
            <MessageBox
              chatbotId={chatbotId}
              chatbotWorkspaceId={chatbotWorkspaceId}
              messageId={messageId}
              variant={variant}
              type={type}
              position={position}
              activeChatId={activeChatId}
              source={source}
              showOnlyWebsiteSource={showOnlyWebsiteSource}
              showResolutionForm={showResolutionForm}
              showAddQAButton={showAddQAButton}
              qualityBadge={qualityBadge}
              style={{
                backgroundColor,
                textColor,
                borderColor,
              }}
              showHumanSupportButton={showHumanSupportButton}
              isQuotaLimitReached={isQuotaLimitReached}
              aiTyping={aiTyping}
              aIFunctionLogs={aIFunctionLogs}
            >
              {children}
            </MessageBox>

            {renderCollectDataForm ? (
              <CollectUserData
                style={{
                  backgroundColor,
                  textColor,
                  borderColor,
                }}
                collectUserData={collectUserData}
                handleSubmit={(value) => {
                  handleSubmitUserData(value);
                }}
                className={cn('inline-flex max-w-full flex-col')}
              />
            ) : null}

            {time && (
              <div
                id="time"
                className={cn(
                  'mt-2 line-clamp-1 flex w-max max-w-full items-center gap-x-1 text-[10px] leading-[14px] text-[#777777]',
                  {
                    ['mr-auto']: position === 'left',
                    ['ml-auto']: position === 'right',
                  },
                )}
              >
                <span className="line-clamp-1">{`${senderName ? senderName : ''}`}</span>

                <span className="h-px w-px rounded-full bg-[#777777]" />

                <span className="w-max flex-none">{`${dayjs(time).locale(languageCode).fromNow()}`}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {renderCustomElement ? (
        <CustomElementRenderer
          key={messageId}
          messageId={messageId}
          elements={customElement}
        />
      ) : null}
    </div>
  );
};

export default Message;
