import { HTMLProps, ReactNode, forwardRef } from 'react';
import { FC } from 'react';
import { SelectHTMLAttributes } from 'react';

import ExclamationCircleIcon from '@heroicons/react/20/solid/ExclamationCircleIcon';
import cn from 'classnames';
import { FieldError } from 'react-hook-form';

import HelpLinkComponent from './help-link.component';

export type Option = {
  value: string | number;
  label: string;
};

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: ReactNode | string;
  helpLink?: string;
  helpText?: ReactNode | string;
  options: Option[];
  error?: FieldError;
  placeholder?: string;
}

const Select: FC<SelectProps & Pick<HTMLProps<HTMLSelectElement>, 'ref'>> =
  forwardRef<HTMLSelectElement, SelectProps>(
    (
      {
        label,
        helpText,
        options,
        placeholder,
        error,
        className,
        helpLink,
        ...rest
      },
      ref,
    ) => {
      return (
        <div data-testid="select-wrapper" className={cn(className)}>
          {label && (
            <label
              data-testid="select-label"
              htmlFor={rest?.id}
              className="mb-2 flex items-center text-sm font-medium leading-6 text-gray-900"
            >
              {label}

              {helpLink ? (
                <HelpLinkComponent
                  data-testid="select-help-link"
                  helpLink={helpLink}
                />
              ) : null}
            </label>
          )}

          <div className="relative">
            <select
              data-testid="select"
              className={cn(
                'block w-full rounded-md border py-2 pl-3 pr-10 ring-0 sm:text-sm',
                {
                  ['border-red-300 text-red-900 placeholder:text-red-300 focus:border-red-500']:
                    !!error,
                  ['border-gray-300 text-gray-900 placeholder:text-gray-500 focus:border-cyan-400']:
                    !error,
                },
              )}
              ref={ref}
              {...rest}
            >
              {placeholder ? (
                <option value="" disabled selected hidden>
                  {placeholder}
                </option>
              ) : null}
              {options.map((option) => (
                <option
                  data-testid="select-option"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            {error && (
              <div
                data-testid="select-error"
                className="pointer-events-none absolute right-0 top-0 flex items-center pr-3 pt-2"
              >
                <ExclamationCircleIcon
                  className="h-5 w-5 bg-white text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {error && (
            <p
              data-testid="select-error-message"
              className="mt-2 text-sm text-red-600"
            >
              {error.message}
            </p>
          )}
          {helpText && (
            <div
              data-testid="select-help-text"
              className="mt-2 text-xs font-normal leading-4 text-gray-500"
            >
              {helpText}
            </div>
          )}
        </div>
      );
    },
  );

Select.displayName = 'Select';

export default Select;
