import { produce } from 'immer';
import { create } from 'zustand';

import type { Message } from '@/components/messenger/types';

interface MessagesState {
  messages: Message[];
  updateMessages: (messages: Message[]) => void;
  addMessage: (message: Message) => void;
  updateMessage: (id: string, partial: Partial<Message>) => void;
  checkIsLastMessage: (id: string) => boolean;
  clearMessages(): void;
}

export const messagesStore = create<MessagesState>((set, get) => ({
  messages: [],
  updateMessages: (messages: Message[]) => set({ messages }),
  addMessage: (message: Message) =>
    set((state) => ({ messages: [...state.messages, message] })),
  updateMessage: (id: string, partial: Partial<Message>) =>
    set((state) => {
      const updatedData = produce(state.messages, (draft) => {
        const messageIndex = draft?.findIndex((message) => message.id === id);
        if (messageIndex === -1) return state.messages;

        draft[messageIndex] = {
          ...draft[messageIndex],
          ...partial,
        };
      });

      return { messages: updatedData };
    }),
  checkIsLastMessage: (id: string) => {
    const messages = get().messages;
    const lastMessage = messages[messages.length - 1];

    return lastMessage?.id === id;
  },
  clearMessages: () => set({ messages: [] }),
}));
