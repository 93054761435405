'use client';

import { FC } from 'react';

import cn from 'classnames';

import { AllElements } from '@/types/elements/type';

import MessengerActionButton from './elements/button';

type CustomElementRendererProps = {
  messageId: string;
  elements?: AllElements;
};

const CustomElementRenderer: FC<CustomElementRendererProps> = ({
  messageId,
  elements,
}) => {
  const renderElement = (items: AllElements) => {
    const elements = items.map((item) => {
      switch (item.type) {
        case 'button':
          return (
            <MessengerActionButton
              key={`${messageId}-${item.type}`}
              {...item.configuration}
            />
          );
      }
    });

    return elements;
  };

  return (
    <div
      data-message-id={messageId}
      id="message-custom-elements"
      className={cn('mt-5 flex w-full flex-col items-center justify-center')}
    >
      {renderElement(elements)}
    </div>
  );
};

export default CustomElementRenderer;
