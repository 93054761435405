import { LanguageCodes } from '@/constants/language-codes-list';
import {
  BROWSER_LANGUAGE_TO_LANGUAGE,
  DEFAULT_ENGLISH,
  DEFAULT_FIELDS,
  TranslationsFields,
} from '@/constants/translation';

export const getDefaultTranslationValues = (
  language: string,
): TranslationsFields => {
  return DEFAULT_FIELDS[language] || DEFAULT_ENGLISH;
};

export const getLanguageCode = (language: string): string => {
  return LanguageCodes[language] || 'en';
};

export type NonDefaultFields = Array<{
  field: keyof TranslationsFields;
  value: string;
}>;
export const getNonDefaultTranslation = (
  fields: TranslationsFields,
  language: string,
): NonDefaultFields => {
  const defaultFields = getDefaultTranslationValues(language);
  const fieldsToCompare = Object.keys(defaultFields);

  const differentTranslations = [];

  fieldsToCompare.forEach((field) => {
    if (defaultFields[field] !== fields[field]) {
      differentTranslations.push({
        field,
        value: fields[field],
      });
    }
  });

  return differentTranslations;
};

export const getBrowserLanguage = (): string => {
  const browserLanguage = navigator.language;
  const language = Object.keys(BROWSER_LANGUAGE_TO_LANGUAGE).find((key) =>
    BROWSER_LANGUAGE_TO_LANGUAGE[key].includes(browserLanguage),
  );
  return language;
};
