'use client';

import { FC } from 'react';

import cn from 'classnames';

import { useModalContext } from '@/context/modalContext';
import { addAlpha } from '@/utils/colors';

import LightningBoltIcon from '../icons/Lightning bolt.component';
import { MessageBoxStyle } from './message-box.component';

type AIActionDebugProps = {
  className?: string;
  position?: 'left' | 'right';
  style: MessageBoxStyle;
  actionName: string;
  actionId: number;
};

const AIActionDebug: FC<AIActionDebugProps> = ({
  className,
  position,
  style,
  actionName,
  actionId,
}) => {
  const { openModal } = useModalContext();
  const softTextColor = addAlpha(style.textColor, 0.65);

  const handleShowDetails = () => {
    openModal({
      id: 'ai-action-debug-modal',
      data: {
        id: actionId,
      },
    });
  };

  return (
    <div className={cn('mt-[6px] flex w-full flex-col', className)}>
      <div
        data-testid="ai-action-debug-wrapper"
        className={cn('w-full max-w-fit rounded-lg px-[18px] py-[12px]', {
          ['mr-auto']: position === 'left',
          ['ml-auto']: position === 'right',
        })}
        style={{
          background:
            style?.backgroundColor ||
            'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
          color: style.textColor,
          border: `1px solid ${style.borderColor}`,
        }}
      >
        <div className="grid grid-cols-[16px_1fr] gap-1.5">
          <LightningBoltIcon
            className="h-4 w-4"
            style={{
              color: softTextColor,
            }}
          />
          <div>
            <div
              className="flex items-center text-xs font-medium"
              style={{
                color: style.textColor,
              }}
            >
              <p className="mr-1.5">{actionName} triggered</p>
              <button className="underline" onClick={handleShowDetails}>
                Details
              </button>
            </div>
            <p
              className="mt-1 text-xs"
              style={{
                color: softTextColor,
              }}
            >
              Only visible in the dashboard preview & chat inbox
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIActionDebug;
