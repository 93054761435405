import React from 'react';

import { ArrowRightIcon } from '@heroicons/react/24/outline';

import { ButtonConfiguration } from '@/types/elements/button/type';

interface MessengerButtonProps extends ButtonConfiguration {}

const MessengerActionButton = ({
  label,
  action,
  url,
}: MessengerButtonProps) => {
  const handleClick = () => {
    if (action === 'GO_TO_URL') {
      window.open(url, '_blank');
    }

    return;
  };

  return (
    <button
      className="relative mx-[38px] grid h-[38px] grid-cols-[1fr_18px] items-center gap-x-2 rounded-full bg-black px-5 text-white shadow-[0px_3px_15px_0px_rgba(0,0,0,0.25)]"
      onClick={handleClick}
    >
      <span className="animated-button-span" />

      <span className="relative z-[2] line-clamp-1 text-sm font-medium leading-5">
        {label}
      </span>
      <ArrowRightIcon className="relative z-[2] h-[18px] w-[18px]" />
    </button>
  );
};

export default MessengerActionButton;
