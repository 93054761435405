export type CloudflareMetadata = {
  hostname: string;
  clientIp: string;
  httpProtocol: string;
  asn: number;
  asOrganization: string;
  colo: string;
  country: string;
  city: string;
  region: string;
  postalCode: string;
  latitude: string;
  longitude: string;
};

export async function getCloudflareMetadata(): Promise<CloudflareMetadata | null> {
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 3000);

    const response = await fetch('https://speed.cloudflare.com/meta', {
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      return null;
    }

    const data = await response.json();
    return { ...data } as CloudflareMetadata;
  } catch (error) {
    return null;
  }
}
