'use client';

import {
  FC,
  Fragment,
  startTransition,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MessageType, Sender } from '@prisma/client';
import cn from 'classnames';
import dayjs from 'dayjs';

import type {
  CustomizeData,
  PlatformFormData,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';
import {
  GPTModels,
  MaxValueOptions,
  SettingData,
} from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import deleteContactUnreadChat from '@/components/messenger/actions/deleteContactUnreadChat';
import { useMessengerContext } from '@/context/messengerContext';
import { handleHumanRouting } from '@/embed/human-routing';
import { messagesStore } from '@/store/messages';
import { messengerStore } from '@/store/messenger';
import { replyStore } from '@/store/reply';
import { inIframe } from '@/utils/element';
import { generateInitialMessagesWithDate } from '@/utils/messenger';

import AgentJoined from './agent-joined.component';
import AIFunctionAnimation from './ai-function-animation.component';
import FollowUpQuestions from './follow-up-questions.component';
import AIMessageTypingAnimation from './message-ai-typing-animation.component';
import MessageArea from './message-area.component';
import MessageInput from './message-input.component';
import MessageComponent from './message.component';
import MessengerClosedBanner from './messenger-closed-banner.component';
import MessengerFooter from './messenger-footer.component';
import SuggestedMessage from './suggested-message.component';
import SupportRequested from './support-requested.component';
import type { Message } from './types';

export type MessengerProps = {
  bodyClassName?: string;
  hideBottomActions?: boolean;
};

const Messenger: FC<MessengerProps> = ({
  bodyClassName,
  hideBottomActions = false,
}) => {
  const { chatbot, aiAgentName, handleAskQuestion, handleSubmitUserData } =
    useMessengerContext();

  const reply = replyStore((state) => state.reply);
  const messages = messagesStore((state) => state.messages);
  const [
    contact,
    activeChat,
    followUpQuestions,
    messageLoading,
    inputDisabled,
    aiFunctionLoadingStatus,
    resetFollowUpQuestions,
  ] = messengerStore((state) => [
    state.contact,
    state.activeChat,
    state.followUpQuestions,
    state.messageLoading,
    state.inputDisabled,
    state.aiFunctionLoadingStatus,
    state.resetFollowUpQuestions,
  ]);

  const isSeen = activeChat?.contactUnreadChats?.length === 0;
  const isChatResolved = activeChat?.isResolved || false;

  const hasBranding = useMemo(() => {
    return chatbot.workspace.plan.hasBranding;
  }, [chatbot]);

  useEffect(() => {
    if (!activeChat?.id || !contact?.id || isSeen) return;

    // If the chat is not seen, we should mark it as seen.
    startTransition(() => {
      deleteContactUnreadChat(activeChat.id, contact.id).then((isDeleted) => {
        if (isDeleted) messengerStore.getState().markChatAsSeen(activeChat.id);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat?.id, isSeen]);

  useEffect(() => {
    return () => {
      resetFollowUpQuestions();
    };
  }, [resetFollowUpQuestions]);

  const handleClickHumanRouting = (platform: PlatformFormData) => {
    const isInIframe = inIframe();

    //if we're in an iframe. We should do the routing in the embed.js section outside the iframe.
    if (isInIframe) {
      window.parent.postMessage(
        { key: 'human-routing', value: platform, chatId: activeChat?.id },
        '*',
      );

      return;
    }

    handleHumanRouting({ item: platform, chatId: activeChat?.id });
  };

  const checkHasResolutionLog = (messageId: string) => {
    if (!activeChat?.chatResolutionLogs) return false;
    if (activeChat?.chatResolutionLogs?.length <= 0) return false;

    return !!activeChat.chatResolutionLogs.find(
      (log) => log.messageId === messageId,
    );
  };

  const checkNewAgentJoined = (message: Message, index: number) => {
    if (message.sender !== Sender.AGENT) return false;

    const prevAgentOrAIMessages = chatbotMessages
      ?.slice(index + 1, messages.length)
      ?.filter(
        (filtered) =>
          filtered.sender === Sender.AGENT || filtered.sender === Sender.AI,
      );

    if (!prevAgentOrAIMessages?.length) return true;

    const containsAgent = prevAgentOrAIMessages?.some(
      (item) => item?.agent?.id === message?.agent?.id,
    );
    if (!containsAgent) return true;

    return false;
  };

  const customize = useMemo(
    () => chatbot?.customize as CustomizeData,
    [chatbot],
  );
  const settings = useMemo(() => chatbot?.settings as SettingData, [chatbot]);

  const initialMessage: Message[] = useMemo(() => {
    const initialMessages = generateInitialMessagesWithDate(customize);
    if (!initialMessages || !initialMessages.length) return [];

    return initialMessages.map((message, index) => ({
      id: `initial-message-${index}`,
      text: message.text,
      createdAt: message.createdAt,
      type: MessageType.TEXT,
      sender: Sender.AI,
      isHumanSupportRequest: false,
      isQuotaLimitReached: false,
    }));
  }, [customize]);

  useEffect(() => {
    if (!!activeChat?.id && !!activeChat?.messages?.length) return;
    if (messages?.length > 0) return;

    if (!!initialMessage?.length) {
      messagesStore.getState().updateMessages(initialMessage);
    }
  }, [activeChat?.id, activeChat?.messages, initialMessage, messages]);

  const chatbotMessages = useMemo(() => {
    return [...(messages || [])].sort(
      (a, b) => dayjs(b?.createdAt).valueOf() - dayjs(a?.createdAt).valueOf(),
    );
  }, [messages]);

  const isUserDataDefined = useMemo(() => {
    return !!(contact?.email || contact?.phone || contact?.name);
  }, [contact?.email, contact?.phone, contact?.name]);

  const showUserDataForm = useMemo(() => {
    if (settings?.collectUserData?.status !== true) return false;
    if (isUserDataDefined) return false;

    if (settings?.collectUserData?.after) {
      const limit = settings?.collectUserData?.after;
      return chatbotMessages?.length >= limit;
    }

    return false;
  }, [
    isUserDataDefined,
    settings?.collectUserData?.status,
    settings?.collectUserData?.after,
    chatbotMessages?.length,
  ]);

  const hasQuotaLimitReached = useMemo(() => {
    return chatbotMessages?.some((message) => message.isQuotaLimitReached);
  }, [chatbotMessages]);

  const messagesContainUserMessage = useMemo(() => {
    return chatbotMessages?.some((message) => message.sender === 'USER');
  }, [chatbotMessages]);

  const showHumanRouting = useMemo(() => {
    if (customize.humanRouting.status !== true) return false;

    if (customize?.humanRouting?.after) {
      const limit = parseInt(customize?.humanRouting?.after);
      return (
        chatbotMessages?.length >= limit &&
        !!customize?.humanRouting?.platforms?.length
      );
    }
  }, [chatbotMessages, customize]);

  const showSuggestedMessages = useMemo(() => {
    if (messagesContainUserMessage) return false;

    return !!customize?.detailedSuggestedMessage?.length;
  }, [customize?.detailedSuggestedMessage, messagesContainUserMessage]);

  const isUserCollectDataDefined = useMemo(() => {
    if (!contact) return false;

    return !!(
      'email' in contact &&
      typeof contact?.email === 'string' &&
      'phone' in contact &&
      typeof contact.phone === 'string' &&
      'name' in contact &&
      typeof contact.name === 'string'
    );
  }, [contact]);

  const messageInputLimit = useMemo(() => {
    return Number(settings?.maxInputLength) || MaxValueOptions.SHORT;
  }, [settings?.maxInputLength]);

  const isLearnMoreEnabled = useMemo(() => {
    if (typeof settings?.isLearnMoreEnabled !== 'boolean') return true;

    return Boolean(settings?.isLearnMoreEnabled);
  }, [settings?.isLearnMoreEnabled]);

  const isLiveChatEnabled = useMemo(() => {
    return chatbot?.isLiveChatEnabled;
  }, [chatbot?.isLiveChatEnabled]);

  const isSatisfactionSurveyEnabled = useMemo(() => {
    return chatbot?.isSatisfactionSurveyEnabled;
  }, [chatbot?.isSatisfactionSurveyEnabled]);

  const isMultiModalGPTModel = useMemo(() => {
    return (
      settings?.gptModel === GPTModels.GPT4o ||
      settings?.gptModel === GPTModels.GPT4oMINI ||
      settings?.gptModel === GPTModels.CLAUDE3SONNET
    );
  }, [settings]);

  const renderBottomActions = useMemo(() => {
    if (hideBottomActions) return false;

    return showHumanRouting || showSuggestedMessages;
  }, [hideBottomActions, showHumanRouting, showSuggestedMessages]);

  const renderFollowUpQuestions =
    followUpQuestions && !!followUpQuestions.length;

  const [isSuggestedMessageLoading, setIsSuggestedMessageLoading] =
    useState(false);

  const handleSuggestedMessageClick = async (message: string) => {
    if (isSuggestedMessageLoading) return;

    setIsSuggestedMessageLoading(true);
    try {
      await handleAskQuestion(message, 'TEXT');
    } finally {
      setIsSuggestedMessageLoading(false);
    }
  };

  return (
    <>
      <div
        id="messenger-body"
        className={cn(
          'flex h-full max-h-full min-h-full flex-col overflow-hidden bg-white',
          bodyClassName,
        )}
      >
        <MessageArea>
          {renderFollowUpQuestions ? (
            <FollowUpQuestions
              followUpQuestions={followUpQuestions}
              userMessageBackgroundColor={customize?.theme?.color?.userMessage}
            />
          ) : null}

          {reply.message ? (
            <MessageComponent
              chatbotId={chatbot.id}
              chatbotWorkspaceId={chatbot.workspaceId}
              variant="minimal"
              time={dayjs().toDate()}
              sender={'AI'}
              source={isLearnMoreEnabled ? reply.source : null}
              agent={null}
              type="TEXT" // AI replies are always text.
              position="left"
              backgroundColor={customize?.theme?.color?.chatbotMessage}
              aiAvatar={customize?.theme?.aiAvatar}
              aiAgentName={aiAgentName}
              collectUserData={settings?.collectUserData}
              activeChatId={activeChat?.id}
              handleSubmitUserData={handleSubmitUserData}
              aiTyping={true}
            >
              {reply.message}
            </MessageComponent>
          ) : null}

          {messageLoading &&
          !reply.message &&
          !activeChat?.isAgent &&
          !hasQuotaLimitReached &&
          aiFunctionLoadingStatus === 'idle' ? (
            <AIMessageTypingAnimation />
          ) : null}

          {aiFunctionLoadingStatus !== 'idle' ? (
            <AIFunctionAnimation status={aiFunctionLoadingStatus} />
          ) : null}

          {chatbotMessages && !!chatbotMessages.length
            ? chatbotMessages.map((message, index) => {
                const isLastMessage = index === 0;
                const isLastAiMessage = message.sender === 'AI' && index === 0;
                const hasResolutionLog = checkHasResolutionLog(message.id);
                const prevMessage = chatbotMessages?.[index - 1];
                const newAgentJoined = checkNewAgentJoined(message, index);
                const position =
                  message.sender === Sender.AGENT ||
                  message.sender === Sender.AI
                    ? 'left'
                    : 'right';

                const livechatOrSatisfactionSurveyEnabled =
                  isLiveChatEnabled || isSatisfactionSurveyEnabled;

                const showResolutionForm =
                  livechatOrSatisfactionSurveyEnabled &&
                  isLastAiMessage &&
                  !hasResolutionLog &&
                  messagesContainUserMessage;

                const showHumanSupportButton =
                  message.isHumanSupportRequest !== true;

                return (
                  <Fragment key={`${message?.id}_${index}`}>
                    {message.isHumanSupportRequest === true &&
                    isLiveChatEnabled ? (
                      <SupportRequested
                        color={{
                          userMessage: customize?.theme?.color?.userMessage,
                          chatbotMessage:
                            customize?.theme?.color?.chatbotMessage,
                        }}
                        aiAvatar={customize?.theme?.aiAvatar}
                        position="left"
                        showEmailForm={
                          (!isUserCollectDataDefined || isLastMessage) &&
                          isLiveChatEnabled
                        }
                      />
                    ) : null}

                    <MessageComponent
                      chatbotId={chatbot.id}
                      chatbotWorkspaceId={chatbot.workspaceId}
                      variant="minimal"
                      time={
                        message.sender !== prevMessage?.sender
                          ? message.createdAt
                          : null
                      }
                      messageId={message?.id}
                      sender={message.sender}
                      agent={message?.agent}
                      type={message.type}
                      position={position}
                      showAvatar={message.sender !== prevMessage?.sender}
                      source={isLearnMoreEnabled ? message.source : null}
                      backgroundColor={
                        message.sender === 'AI' || message.sender === 'AGENT'
                          ? customize?.theme?.color?.chatbotMessage
                          : customize?.theme?.color?.userMessage
                      }
                      aiAvatar={customize?.theme?.aiAvatar}
                      aiAgentName={aiAgentName}
                      showResolutionForm={showResolutionForm}
                      showUserDataForm={
                        !message.isHumanSupportRequest &&
                        showUserDataForm &&
                        message.sender === 'AI' &&
                        isLastAiMessage
                      }
                      showHumanSupportButton={showHumanSupportButton}
                      collectUserData={settings?.collectUserData}
                      handleSubmitUserData={handleSubmitUserData}
                      activeChatId={activeChat?.id}
                      isQuotaLimitReached={hasQuotaLimitReached}
                      customElement={message?.customElement}
                      aIFunctionLogs={message?.aIFunctionLogs}
                    >
                      {message.text}
                    </MessageComponent>

                    {newAgentJoined ? (
                      <AgentJoined agentName={message?.agent?.name} />
                    ) : null}
                  </Fragment>
                );
              })
            : null}
        </MessageArea>
      </div>
      <div
        id="messenger-bottom"
        className="relative bottom-0 z-[1] h-auto w-full overflow-hidden bg-white"
      >
        {isChatResolved ? (
          <MessengerClosedBanner closedBy={activeChat?.resolvedBy} />
        ) : (
          <>
            {renderBottomActions ? (
              <div className="w-full overflow-x-auto pb-[15px] pt-1">
                {showHumanRouting ? (
                  <div
                    id="human-routing-wrapper"
                    className="flex w-max flex-nowrap gap-[8px] px-[15px]"
                  >
                    {customize?.humanRouting?.platforms?.map((item) => (
                      <SuggestedMessage
                        key={item.platform}
                        value={item.text}
                        platform={item.platform}
                        backgroundColor={customize?.theme?.color?.userMessage}
                        handleClick={() => handleClickHumanRouting(item)}
                        isLoading={isSuggestedMessageLoading}
                      />
                    ))}
                  </div>
                ) : null}
                {showSuggestedMessages ? (
                  <div
                    id="suggested-message-wrapper"
                    className="flex w-max flex-nowrap gap-[8px] px-[15px]"
                  >
                    {customize?.detailedSuggestedMessage.map(
                      (message, index) => (
                        <SuggestedMessage
                          key={index}
                          value={message.label}
                          backgroundColor={customize?.theme?.color?.userMessage}
                          handleClick={() =>
                            handleSuggestedMessageClick(message.message)
                          }
                          isLoading={isSuggestedMessageLoading}
                        />
                      ),
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}

            {!messageLoading && (
              <MessageInput
                showImageInput={activeChat?.isAgent || isMultiModalGPTModel} // If the chat is with an agent or the chatbot is using a multi-modal GPT model, user can send images.
                handleSubmit={handleAskQuestion}
                messageLimit={messageInputLimit}
                disabled={messageLoading || inputDisabled}
                loading={messageLoading}
              />
            )}
          </>
        )}

        <MessengerFooter
          removeBranding={customize?.theme?.removeBranding && !hasBranding}
        />
      </div>
    </>
  );
};

export default Messenger;
