import { FC } from 'react';

import { SvgProps } from './types';

const LightningBoltIcon: FC<SvgProps> = ({
  className,
  width = '16',
  height = '16',
  style,
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.66675 6.66666V2L2.66675 9.33333H7.33342L7.33342 14L13.3334 6.66667L8.66675 6.66666Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LightningBoltIcon;
