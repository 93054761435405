'use client';

import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { ChatType, MessageType, Sender } from '@prisma/client';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import removeMd from 'remove-markdown';

import { SettingData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import createAgentUnreadChats from '@/app/actions/createAgentUnreadChats';
import { GetChatbotDetailReturnType } from '@/app/actions/getChatbotDetail';
import { getChatbotUserMessageCount } from '@/app/actions/getChatbotUserMessageCount';
import { getContact } from '@/app/actions/getContact';
import { getContactByChatId } from '@/app/actions/getContactByChatId';
import { insertResolutionLog } from '@/app/actions/insertResolutionLog';
import { requestHumanSupport } from '@/app/actions/requestHumanSupport';
import { saveContactData } from '@/app/actions/saveContactData';
import { sendEventToMake } from '@/app/actions/sendEventToMake';
import { sendUnassignedConversationEmail } from '@/app/actions/sendUnassignedConversationEmail';
import { updateChats } from '@/app/actions/updateChats';
import { updateContactSource } from '@/app/actions/updateContactSource';
import { UserIntent } from '@/app/api/aibot/user-intent/types';
import { FollowUpQuestionSchema } from '@/app/schema/follow-up-question';
import deleteContactUnreadChat from '@/components/messenger/actions/deleteContactUnreadChat';
import type { MessengerChat } from '@/components/messenger/types';
import SuccessToast from '@/components/toasts/success-toast.component';
import WarningToast from '@/components/toasts/warning-toast.component';
import { TranslationsFields } from '@/constants/translation';
import {
  EventStreamEndMessage,
  EventStreamErrorMessage,
  EventStreamEventData,
} from '@/helpers/eventStream';
import { getParentLocation } from '@/helpers/getParentLocation';
import { SaveQuestionResponse } from '@/helpers/saveQuestion';
import useContactChannelListener from '@/hooks/useContactChannelListener';
import { useTranslationUpdate } from '@/hooks/useTranslationUpdate';
import { messagesStore } from '@/store/messages';
import { messengerStore } from '@/store/messenger';
import { replyStore } from '@/store/reply';
import useTranslationStore from '@/store/translation';
import { getCloudflareMetadata } from '@/utils/cloudflare';
import { inIframe } from '@/utils/element';
import { GTAGTracker } from '@/utils/gtag';
import LocalStorage from '@/utils/local-storage';
import { getMessageType } from '@/utils/message';
import { clearReply } from '@/utils/messenger';
import { safeJsonParse } from '@/utils/object';
import { sendClientSidePosthogEvent } from '@/utils/posthog';
import { getChatbotSettings } from '@/utils/settings';

dayjs.extend(isSameOrBefore);

export type AIFunctionAnimationStatus = 'idle' | 'run' | 'format';
export type Screen = 'list' | 'messenger';

type MessengerContextType = {
  isEmbed: boolean;
  chatType: ChatType;
  chatbot: GetChatbotDetailReturnType;
  aiAgentName: string;
  isFullHeight: boolean;
  fields: TranslationsFields;
  handleAskQuestion: (
    question: string,
    type: MessageType,
    isFollowUp?: boolean,
  ) => Promise<void>;
  handleSubmitUserData: (data: {
    email?: string;
    phone?: string;
    fullName?: string;
  }) => Promise<boolean>;
  handleGetContact: (contactId: string) => Promise<void>;
  handleCloseMessenger: () => void;
  updateActiveChat: (chatId: string | null) => void;
  updateConverationListView: ({
    view,
    scrollTop,
  }: {
    view: 'minimal' | 'banner' | 'dynamic';
    scrollTop?: number;
  }) => void;
};

export const MessengerContext = createContext<MessengerContextType>({
  isEmbed: false,
  chatbot: null,
  chatType: 'MESSENGER',
  aiAgentName: 'AI Agent',
  fields: null,
  isFullHeight: false,
  handleAskQuestion: async (question, type, isFollowUp) => {},
  handleSubmitUserData: async (data) => {
    return false;
  },
  handleGetContact: async (contactId: string) => {},
  handleCloseMessenger: () => {},
  updateActiveChat: (chatId: string | null) => {},
  updateConverationListView: ({ view, scrollTop }) => {},
});

type MessengerProviderProps = {
  children: ReactNode;
  chatbot: GetChatbotDetailReturnType;
  isFullHeight?: boolean;
  isPreviewPage?: boolean;
  chatType: ChatType;
  fields: TranslationsFields;
  supportedLanguagesFields: {
    [key: string]: TranslationsFields;
  };
  isEmbed?: boolean;
  activeChatId?: string;
};

const ignoredErrorMessagesParts = [
  "Failed to execute 'getReader' on 'ReadableStream'",
];

export const MessengerProvider: FC<MessengerProviderProps> = ({
  children,
  chatbot,
  fields,
  supportedLanguagesFields,
  isFullHeight,
  isPreviewPage = false,
  chatType,
  isEmbed = false,
  activeChatId,
}) => {
  const chatbotPlan = chatbot?.workspace?.plan;
  const aiAgentName = (chatbot?.settings as SettingData)?.aiName || 'AI Agent';

  const queryClient = useQueryClient();

  const showNewMessageToast = (toastId: string, message: string) => {
    toast(
      <SuccessToast
        type="embed"
        title={useTranslationStore.getState().getValue('newMessageToast')}
        detail={message}
      />,
      {
        position: 'top-center',
        closeButton: false,
        toastId: toastId,
      },
    );
  };

  const updateNewMessageToast = (toastId: string, message: string) => {
    toast.update(toastId, {
      render: (
        <SuccessToast
          type="embed"
          title={useTranslationStore.getState().getValue('newMessageToast')}
          detail={message}
        />
      ),
      position: 'top-center',
      closeButton: false,
    });
  };

  const audioRef = useRef(null);

  const playMessageSound = () => {
    try {
      if (!audioRef.current) return;

      audioRef.current?.play();
    } catch {}
  };

  const handleMessageCallback = async (
    chatId: string,
    message: SaveQuestionResponse,
  ) => {
    if (message?.sender !== 'AGENT') return;

    const humanMessage = {
      ...message,
      isHumanSupportRequest: false,
      isQuotaLimitReached: false,
      aIFunctionLogs: [],
    };

    window.parent.postMessage({ key: 'message', value: message }, '*');

    if (messengerStore.getState().activeScreen === 'list') {
      const messageText =
        getMessageType(message.text, message.type) === MessageType.TEXT
          ? removeMd(message?.text || '')
          : 'Sent an image';

      //If toast is already active, update it
      if (toast.isActive(`messenger-new-message-${chatId}`)) {
        updateNewMessageToast(`messenger-new-message-${chatId}`, messageText);
      } else {
        //Else show new message toast
        showNewMessageToast(`messenger-new-message-${chatId}`, messageText);
      }

      playMessageSound();
    }

    const activeChat = messengerStore.getState().activeChat;
    const contactId = messengerStore.getState().contact?.id;
    if (activeChat?.id === chatId) {
      const messages = messagesStore.getState().messages;
      if (messages?.find((m) => m.id === message.id)) return;

      await deleteContactUnreadChat(chatId, contactId);

      messagesStore.getState().addMessage(humanMessage);
      messengerStore.getState().updateActiveChat({
        messages: [...activeChat?.messages, humanMessage],
      });
    }
    messengerStore.getState().addMessageToContact(chatId, humanMessage);
  };

  const handleCloseMessenger = () => {
    window.parent.postMessage(
      { key: 'close-messenger', value: chatbot.id },
      '*',
    );
  };

  const handleEnterCallback = (
    connectionId: string,
    type: Sender,
    id: string,
  ) => {
    if (type === 'AGENT') {
      messengerStore.getState().addOnlineAgent(id, connectionId);
    }
  };

  const handleLeaveCallback = (
    connectionId: string,
    type: Sender,
    id: string,
  ) => {
    if (type === 'AGENT') {
      messengerStore.getState().removeOnlineAgent(id, connectionId);
    }
  };

  useContactChannelListener({
    chatbotId: chatbot.id,
    handleMessageCallback,
    handleEnterCallback,
    handleLeaveCallback,
  });

  const setChatMessages = (chat: MessengerChat) => {
    messagesStore
      .getState()
      .updateMessages(
        [...(chat?.messages || [])].sort(
          (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
        ),
      );
  };

  const getMessengerLocation = (): string => {
    const isInIframe = inIframe();

    //Not in iframe, return current location
    if (!isInIframe) return window.location.href;

    //embed.js send messengerLocation
    const messengerLocation = messengerStore.getState().messengerLocation;
    if (messengerLocation) return messengerLocation;

    //In iframe without embed.js, return current location
    return window.location.href;
  };

  const handleCheckUserIntent = useCallback(
    async (message: string): Promise<{ intent: UserIntent }> => {
      try {
        const hasCustomOpenAIAPIAccess = chatbotPlan?.hasCustomOpenAIAPI;
        const openAIApiKey = hasCustomOpenAIAPIAccess
          ? chatbot?.openAIApiKey
          : null;

        const intentResponse = await fetch('/api/aibot/user-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message,
            openAIApiKey,
            chatbotId: chatbot.id,
            workspaceId: chatbot.workspaceId,
          }),
        });

        const json = await intentResponse.json();

        if (json.status === 'error') {
          return {
            intent: UserIntent.NEUTRAL,
          };
        }

        return json.result;
      } catch (error) {
        return {
          intent: UserIntent.NEUTRAL,
        };
      }
    },
    [
      chatbot?.openAIApiKey,
      chatbotPlan?.hasCustomOpenAIAPI,
      chatbot.id,
      chatbot.workspaceId,
    ],
  );

  const handleGetFollowUpQuestions = useCallback(
    async ({
      message,
      chatId,
      chatbotId,
    }: {
      message: string;
      chatId: string;
      chatbotId: string;
    }): Promise<FollowUpQuestionSchema> => {
      try {
        const response = await fetch('/api/v1/aibot/follow-up', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chatId,
            chatbotId,
            question: message,
          }),
        });

        if (!response.ok) {
          return null;
        }

        const json = await response.json();

        if (json.status === 'error') {
          return null;
        }

        return json?.question;
      } catch (error) {
        return null;
      }
    },
    [],
  );

  const handleAIHelped = useCallback(
    async (chatId: string) => {
      try {
        //When isIntentRoutingEnabled is true, we should update chat
        if (chatbot.isLiveChatEnabled && chatbot.isIntentRoutingEnabled) {
          await updateChats({ chatId, isAIHelped: true });

          messengerStore.getState().updateActiveChat({
            isAIHelped: true,
          });
        }

        await insertResolutionLog({
          chatId,
          isAIHelped: true,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [chatbot.isIntentRoutingEnabled, chatbot.isLiveChatEnabled],
  );

  const handleAINotHelped = useCallback(
    async (chatId: string) => {
      try {
        //When isIntentRoutingEnabled is true, we should update chat
        if (chatbot.isLiveChatEnabled && chatbot.isIntentRoutingEnabled) {
          await updateChats({
            chatId: chatId,
            isAIHelped: false,
            //If the user clicks on the "Talk to an agent" button, the AI leaves the conversation and the agent takes over.
            isAgent: true,
          });

          const message = await requestHumanSupport({
            chatId,
            isQuotaLimitReached: true,
          });
          await createAgentUnreadChats(chatId);

          messagesStore.getState().updateMessage(message.id, {
            isHumanSupportRequest: true,
            isQuotaLimitReached: true,
          });
          messengerStore.getState().updateActiveChat({
            isAIHelped: false,
            isAgent: true,
          });
        }

        await insertResolutionLog({
          chatId: chatId,
          isAIHelped: false,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [chatbot.isIntentRoutingEnabled, chatbot.isLiveChatEnabled],
  );

  const handleSendEvents = useCallback(
    async (chatbotId: string, question: string, answer: string) => {
      if (!isPreviewPage) return;

      const stateUserMessageCount =
        messagesStore
          .getState()
          .messages.filter((message) => message.sender === 'USER')?.length ?? 0;

      // check message count from the server
      const userMessageCount =
        stateUserMessageCount <= 1
          ? await getChatbotUserMessageCount(chatbotId)
          : stateUserMessageCount;

      if (userMessageCount <= 1) {
        GTAGTracker('app_first_message_sent', {
          ai_bot_id: chatbotId,
        });

        sendClientSidePosthogEvent('first_message_sent', {
          chatbotId: chatbotId,
          question: question,
          answer: answer,
        });
      }

      if (userMessageCount <= 3) {
        let event = null;
        switch (userMessageCount) {
          case 1:
            event = 'first_message_sent';
            break;
          case 2:
            event = 'second_message_sent';
            break;
          default:
            event = 'third_message_sent';
            break;
        }

        await sendEventToMake({
          eventType: event,
          value: `Question: ${question}.\n Answer: ${answer}`,
          chatbotId: chatbotId,
        });
      }
    },
    [isPreviewPage],
  );

  const handleAIResponse = useCallback(
    async ({
      chatbotId,
      contactId,
      chatId,
      question,
      type,
      isFollowUp = false,
    }: {
      chatbotId: string;
      contactId: string;
      chatId: string;
      question: string;
      type: MessageType;
      isFollowUp?: boolean;
    }): Promise<{
      chatId: string;
      messageId: string;
    }> => {
      const controller = new AbortController();
      const animatedLetterCount = 2;
      let remainText = '';
      let messageId = null;
      let _chatId = chatId;
      let dataLinks = null;
      let aIFunctionLogs = null;
      let animationId = null;
      let isAgentStatus = false;
      let quotaLimitReached = false;

      const handleVisibilityChange = () => {
        if (document.hidden) {
          cancelAnimationFrame(animationId);

          replyStore.getState().addToReplyMessage(remainText);
          remainText = '';

          window.parent.postMessage(
            { key: 'animation', value: 'aiTypingEnd' },
            '*',
          );
        } else {
          if (remainText.length > 0) {
            animationId = requestAnimationFrame(animateResponseText);
          }
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Type animation function
      function animateResponseText() {
        if (controller.signal.aborted) {
          replyStore.getState().addToReplyMessage(remainText);
          remainText = '';
        }

        if (remainText.length > 0) {
          const fetchText = remainText.slice(0, animatedLetterCount);
          replyStore.getState().addToReplyMessage(fetchText);

          remainText = remainText.slice(animatedLetterCount);
        }

        animationId = requestAnimationFrame(animateResponseText);
      }

      // Start animation
      animateResponseText();

      const cloudflareMetaData = await getCloudflareMetadata();
      const messengerLocation = getMessengerLocation();

      await fetchEventSource('/api/v1/aibot/reply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chatbotId,
          contactId,
          chatId,
          question,
          messageType: type,
          isFollowUp,
          streaming: true,
          ip: cloudflareMetaData?.clientIp,
          country: cloudflareMetaData?.country,
          location: messengerLocation,
        }),
        signal: controller.signal,
        openWhenHidden: true,
        async onopen(response) {
          //Everything is good
          if (
            response.ok &&
            response.headers.get('content-type') === EventStreamContentType
          ) {
            return;
          }

          //Rate limit error
          if (response.status === 429) {
            const message =
              (await response.json()).message || 'Too many requests';
            throw new Error(message);
          }

          //Internal server error
          if (response.status === 500) {
            const message =
              (await response.json()).message || 'Something went wrong';
            throw new Error(message);
          }

          //Unauthorized error
          if (!response.ok) {
            throw new Error(response.statusText);
          }

          // JSON response
          if (
            response.status === 200 &&
            response.headers.get('content-type') === 'application/json'
          ) {
            const {
              isAgent,
              newMessage,
              isQuotaLimitReached = false,
              limitReachedMessage,
            } = await response.json();

            if (newMessage?.id) {
              messageId = newMessage.id;
            }

            if (isAgent) {
              isAgentStatus = true;
            }

            const isLiveChatEnabled = chatbot?.isLiveChatEnabled;

            const hasQuotaLimitReached = !!messagesStore
              .getState()
              .messages?.find((message) => message.isQuotaLimitReached);

            quotaLimitReached = isQuotaLimitReached || hasQuotaLimitReached;

            if (isAgent || isQuotaLimitReached) {
              controller.abort();
              // stop type animation
              cancelAnimationFrame(animationId);

              window.parent.postMessage(
                { key: 'animation', value: 'aiTypingEnd' },
                '*',
              );

              if (!hasQuotaLimitReached) {
                toast(
                  <WarningToast
                    type="embed"
                    detail={
                      "We can't answer because the AI bot-message quota has been exceeded. Please talk to an agent or contact the site owner."
                    }
                    dangerouslySetInnerHTML
                  />,
                  {
                    position: 'top-center',
                    closeButton: false,
                  },
                );

                messagesStore.getState().addMessage({
                  id: messageId,
                  text: limitReachedMessage,
                  sender: 'AI',
                  type: 'TEXT',
                  createdAt: dayjs().toDate(),
                  isHumanSupportRequest: isLiveChatEnabled,
                  isQuotaLimitReached: true,
                });

                if (isLiveChatEnabled) {
                  await sendUnassignedConversationEmail({ chatId });
                }
              }

              messengerStore.getState().setMessageLoading(false);
              messengerStore.getState().updateActiveChat({
                isAgent,
              });

              await createAgentUnreadChats(chatId);
            }
          }
        },
        async onmessage(message) {
          if (message.event === 'data' && message.data) {
            const data = safeJsonParse<EventStreamEventData>(message.data);

            if (data.type === 'chunk') {
              const _result = clearReply(remainText);
              remainText = _result + data.token;
            }

            if (data.type === 'action-status') {
              messengerStore.getState().setAiFunctionLoadingStatus(data.status);
            }

            if (data.type === 'action-element') {
              replyStore.getState().updateReply({
                customElement: data.element,
              });
            }

            if (data.type === 'contact-created') {
              const newContactId = data?.contactId;
              const stateContactId = messengerStore.getState()?.contact?.id;

              if (newContactId && stateContactId !== newContactId) {
                try {
                  messengerStore.getState().updateContact({
                    id: newContactId,
                    email: null,
                    name: null,
                    chatSource: null,
                    phone: null,
                    chats: [],
                  });
                  LocalStorage.set(`live-chat-ai-${chatbot.id}`, newContactId);
                } catch (e) {}
              }
            }

            if (data.type === 'chat-created') {
              const newChatId = data?.chatId;
              const stateChatId = messengerStore.getState().activeChat?.id;

              if (newChatId && stateChatId !== newChatId) {
                _chatId = newChatId;

                messengerStore.getState().updateActiveChat({
                  id: newChatId,
                  messages: [],
                  createdAt: dayjs().toDate(),
                  isAIHelped: true,
                  isAgent: false,
                  isMuted: false,
                  isResolved: false,
                  isStarred: false,
                  resolvedBy: null,
                  chatResolutionLogs: [],
                  agentUnreadChats: [],
                  contactUnreadChats: [],
                });
              }
            }
          }

          if (message.event === 'end' && message.data) {
            const data = safeJsonParse<EventStreamEndMessage>(message.data);

            messageId = data.id;
            dataLinks = data.links;
            aIFunctionLogs = data.functionLog;
          }

          if (message.event === 'error' && message.data) {
            const data = safeJsonParse<EventStreamErrorMessage>(message.data);

            throw new Error(data.message);
          }
        },
        onclose() {
          if (isPreviewPage) {
            queryClient.invalidateQueries({
              queryKey: [`chatbot_first-message_${chatbot.id}`],
            });
          }

          if (messengerStore.getState().activeChat?.isAgent) {
            messengerStore.getState().updateActiveChat({
              isAgent: false,
            });
          }

          window.parent.postMessage(
            { key: 'animation', value: 'aiTypingEnd' },
            '*',
          );
        },
        onerror(err) {
          const isIgnoredError = ignoredErrorMessagesParts.some((part) =>
            err?.message?.includes(part),
          );
          if (isIgnoredError) return;

          throw new Error(err);
        },
      });

      if (!isAgentStatus && !quotaLimitReached) {
        // Wait until remainText is empty before proceeding
        await new Promise<void>((resolve) => {
          const checkRemainText = setInterval(() => {
            if (remainText.length <= 0) {
              clearInterval(checkRemainText);
              resolve(); // Resolve once remainText is empty
            }
          }, 50); // Check every 100ms
        });

        // stop type animation
        cancelAnimationFrame(animationId);
        remainText = '';

        const responseMessage = replyStore.getState().reply.message;
        const customElement = replyStore.getState().reply.customElement;

        messagesStore.getState().addMessage({
          id: messageId,
          text: responseMessage,
          sender: 'AI',
          type: 'TEXT', // AI message type is always text
          source: dataLinks,
          agent: null,
          createdAt: dayjs().toDate(),
          isHumanSupportRequest: false,
          customElement,
          aIFunctionLogs,
        });

        messengerStore.getState().setMessageLoading(false);
        replyStore.getState().clearReply();

        await handleSendEvents(chatbot.id, question, responseMessage);
      }

      return {
        chatId: _chatId,
        messageId,
      };
    },
    [
      chatbot?.id,
      chatbot?.isLiveChatEnabled,
      isPreviewPage,
      queryClient,
      handleSendEvents,
    ],
  );

  const handleAskQuestion = useCallback(
    async (question: string, type: MessageType, isFollowUp?: boolean) => {
      try {
        let chatId = messengerStore.getState().activeChat?.id;
        let contactId = messengerStore.getState().contact?.id;

        const lastMessageDate =
          messagesStore.getState().messages?.[
            messagesStore.getState().messages.length - 1
          ]?.createdAt;

        //if now greater than last message date, we should use this date, otherwise we should use last message date + 1 second
        const createdAt = dayjs().isSameOrBefore(lastMessageDate)
          ? dayjs(lastMessageDate).add(1, 'second').toDate()
          : dayjs().toDate();

        messagesStore.getState().addMessage({
          type,
          text: question,
          sender: 'USER',
          createdAt: createdAt,
          isHumanSupportRequest: false,
          isQuotaLimitReached: false,
        });

        replyStore.getState().clearReply();
        messengerStore.getState().resetFollowUpQuestions();
        messengerStore.getState().setMessageLoading(true);
        window.parent.postMessage(
          { key: 'animation', value: 'aiTypingStart' },
          '*',
        );

        const isAgentChat = messengerStore.getState().activeChat?.isAgent;
        const settings = getChatbotSettings(chatbot?.settings as SettingData);
        const followUpQuestionEnabled = settings.followUpQuestionEnabled;

        let intent = UserIntent.NEUTRAL;

        const [response, intentResult, followUpQuestions] = await Promise.all([
          handleAIResponse({
            chatbotId: chatbot.id,
            contactId,
            chatId,
            question,
            type,
            isFollowUp,
          }),
          !isAgentChat
            ? handleCheckUserIntent(question)
            : Promise.resolve(null),
          followUpQuestionEnabled
            ? handleGetFollowUpQuestions({
                message: question,
                chatId,
                chatbotId: chatbot.id,
              })
            : Promise.resolve(null),
        ]);

        if (response?.chatId) {
          chatId = response.chatId;
        }

        if (followUpQuestions && !!followUpQuestions?.length) {
          messengerStore.getState().addFollowUpQuestions(followUpQuestions);
        }

        //If active chat is not agent, we should check user intent
        if (!isAgentChat && intentResult) {
          intent = intentResult.intent;
        }

        if (intent === UserIntent.AI_HELPED_THE_USER) {
          handleAIHelped(chatId);
        }

        if (intent === UserIntent.USER_WANTS_HUMAN_SUPPORT) {
          handleAINotHelped(chatId);
        }
      } catch (error) {
        messengerStore.getState().setMessageLoading(false);
        window.parent.postMessage(
          { key: 'animation', value: 'aiTypingEnd' },
          '*',
        );

        toast(
          <WarningToast
            type="embed"
            detail={error?.message || 'Something went wrong'}
            dangerouslySetInnerHTML
          />,
          {
            position: 'top-center',
            closeButton: false,
          },
        );
      }
    },
    [
      chatbot?.id,
      chatbot?.settings,
      handleAIHelped,
      handleAINotHelped,
      handleCheckUserIntent,
      handleAIResponse,
      handleGetFollowUpQuestions,
    ],
  );

  const handleSubmitUserData = async (data: {
    email?: string;
    phone?: string;
    fullName?: string;
  }) => {
    try {
      const contactId = messengerStore.getState().contact.id;

      const { status, contact, message } = await saveContactData({
        contactId,
        ...data,
      });

      if (status === 'error') {
        toast(
          <WarningToast
            type="embed"
            detail={useTranslationStore.getState().getValue('defaultError')}
          />,
          {
            position: 'top-center',
            closeButton: false,
          },
        );
        return false; // Hata durumunda false döndür
      }

      toast(
        <SuccessToast
          type="embed"
          detail={useTranslationStore.getState().getValue('userDataSuccess')}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );

      messengerStore.getState().updateContact({
        ...messengerStore.getState().contact,
        ...contact,
      });

      return true; // Başarılı durumda true döndür
    } catch (e) {
      toast(
        <WarningToast
          type="embed"
          detail={useTranslationStore.getState().getValue('defaultError')}
        />,
        {
          position: 'top-center',
          closeButton: false,
        },
      );
      return false; // Hata durumunda false döndür
    }
  };

  const handleGetContact = async (contactId: string) => {
    messengerStore.getState().setLocalDataLoading(true);

    const contact = await getContact(contactId);
    messengerStore.getState().updateContact(contact);
    if (!contact.chatSource) {
      const href = getParentLocation();
      updateContactSource(contact.id, href);
    }

    messengerStore.getState().setLocalDataLoading(false);
  };

  const updateActiveChat = (chatId: string | null) => {
    if (!chatId) {
      messengerStore.getState().setActiveChat(null);
      messagesStore.getState().clearMessages();
      return;
    }

    const contact = messengerStore.getState().contact;
    const contactChat = contact.chats?.find((chat) => chat.id === chatId);

    if (contactChat) {
      const chat = {
        ...contactChat,
        chatbotId: chatbot.id,
        chatType: chatType,
      };

      messengerStore.getState().setActiveChat(chat);
      setChatMessages(chat);
    }
  };

  const getActiveContactAndChat = useCallback(
    async (activeChatId: string) => {
      messengerStore.getState().setLocalDataLoading(true);

      const contact = await getContactByChatId(activeChatId);
      if (!contact) {
        messengerStore.getState().setLocalDataLoading(false);
        return;
      }

      messengerStore.getState().updateContact(contact);

      const contactChat = contact.chats?.find(
        (chat) => chat.id === activeChatId,
      );
      if (!contactChat) {
        messengerStore.getState().setLocalDataLoading(false);
        return;
      }

      const chat = {
        ...contactChat,
        chatbotId: chatbot.id,
        chatType: chatType,
      };

      messengerStore.getState().updateScreen('messenger');
      messengerStore.getState().setActiveChat(chat);
      setChatMessages(chat);

      messengerStore.getState().setLocalDataLoading(false);
    },
    [chatbot.id, chatType],
  );

  const activateMinimalView = () => {
    if (!document) return;
    const root = document.documentElement;
    if (!root) return;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      '48px 1fr auto',
    );
    root.style.setProperty('--conversation-list-radius', '0px');
    root.style.setProperty('--header-height', '48px');
    root.style.setProperty('--header-min-height', 'auto');
    root.style.setProperty('--header-justify-content', 'space-between');
    root.style.setProperty('--header-agents-display', 'none');
    root.style.setProperty('--header-close-position', 'static');
  };

  const activateBannerView = () => {
    if (!document) return;
    const root = document.documentElement;
    if (!root) return;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      '190px 1fr auto',
    );
    root.style.setProperty('--conversation-list-radius', '25px');
    root.style.setProperty('--header-height', 'auto');
    root.style.setProperty('--header-min-height', '198px');
    root.style.setProperty('--header-justify-content', 'center');
    root.style.setProperty('--header-agents-display', 'flex');
    root.style.setProperty('--header-close-position', 'absolute');
  };

  const activateDynamicView = (scrollTop: number) => {
    if (!document || !scrollTop) return;

    const root = document.documentElement;
    if (!root) return;

    const height = 198 - scrollTop / 3;

    root.style.setProperty(
      '--wrapper-dynamic-grid-template-rows',
      `${Math.max(height, 48)}px 1fr auto`,
    );
    root.style.setProperty(
      '--conversation-list-radius',
      `${24 - scrollTop / 3}px`,
    );
    root.style.setProperty('--header-height', `${height}px`);
    root.style.setProperty('--header-min-height', 'auto');
    root.style.setProperty('--header-justify-content', 'center');
    root.style.setProperty('--header-close-position', 'absolute');

    if (height < 160) {
      root.style.setProperty('--header-agents-display', 'none');
    } else {
      root.style.setProperty('--header-agents-display', 'flex');
    }
  };

  const updateConverationListView = ({
    view,
    scrollTop,
  }: {
    view: 'minimal' | 'banner' | 'dynamic';
    scrollTop?: number;
  }) => {
    switch (view) {
      case 'minimal':
        activateMinimalView();
        break;
      case 'banner':
        activateBannerView();
        break;
      case 'dynamic':
        activateDynamicView(scrollTop);
        break;
    }
  };

  useTranslationUpdate({
    chatbot: {
      id: chatbot?.id,
      language: chatbot?.language,
      isAutoTranslationEnabled: chatbot?.isAutoTranslationEnabled,
      supportedLanguages: chatbot?.supportedLanguages,
    },
    fields,
    supportedLanguagesFields,
    shouldFetchFields: false,
    shouldReset: true,
  });

  useEffect(() => {
    return () => {
      messengerStore.getState().reset();
      messagesStore.getState().clearMessages();
      replyStore.getState().clearReply();
    };
  }, []);

  useEffect(() => {
    if (!window) return;

    const isInIframe = inIframe();
    if (!isInIframe) return;

    const handleMessage = (event: MessageEvent) => {
      if (event.data?.key === 'messenger-location') {
        const location = event?.data?.value;

        if (location) {
          messengerStore.getState().setMessengerLocation(location);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ key: 'messenger-ready' }, '*');

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    if (!activeChatId) return;

    getActiveContactAndChat(activeChatId);

    return () => {
      messengerStore.getState().updateContact(null);
      messengerStore.getState().setActiveChat(null);
      messagesStore.getState().clearMessages();
    };
  }, [getActiveContactAndChat, activeChatId]);

  return (
    <MessengerContext.Provider
      value={{
        isEmbed,
        chatbot,
        chatType,
        aiAgentName,
        isFullHeight,
        fields,
        handleAskQuestion,
        handleSubmitUserData,
        handleGetContact,
        handleCloseMessenger,
        updateActiveChat,
        updateConverationListView,
      }}
    >
      {children}

      <Tooltip
        id="messenger-tooltip"
        className="messenger-tooltip"
        classNameArrow="messenger-tooltip-arrow"
      />
      <audio ref={audioRef}>
        <source src="/assets/sounds/bell-notification.mp3" type="audio/mpeg" />
      </audio>
    </MessengerContext.Provider>
  );
};

export const useMessengerContext = () => useContext(MessengerContext);
