'use client';

import { FC, useState } from 'react';

import { Sender } from '@prisma/client';
import cn from 'classnames';
import { useColorPicker } from 'react-best-gradient-color-picker';

import { useMessengerContext } from '@/context/messengerContext';
import useTranslationStore from '@/store/translation';
import { addAlpha, getTextColor } from '@/utils/colors';

import AILogo from '@public/assets/images/ai-agent.png';

import Avatar from '../avatar.component';
import CollectUserData from './collect-user-data.component';

type SupportRequestedProps = {
  color: {
    userMessage: string;
    chatbotMessage: string;
  };
  showEmailForm?: boolean;
  aiAvatar?: string;
  position: 'left' | 'right';
};

const SupportRequested: FC<SupportRequestedProps> = ({
  color,
  showEmailForm = false,
  aiAvatar,
  position,
}) => {
  const { getValue } = useTranslationStore();
  const [showCollectUserData, setShowCollectUserData] = useState(showEmailForm);

  return (
    <div className="flex w-full flex-col">
      <FakeMessage
        sender="AI"
        showEmailForm={showCollectUserData}
        position={position}
        message={
          <>
            <h5 className="text-[14px] font-medium leading-5">
              {getValue('supportRequestedTitle')}
            </h5>
            <p className="mt-[10px] text-xs">
              {getValue('supportRequestedDescription')}
            </p>
          </>
        }
        backgroundColor={color?.chatbotMessage}
        aiAvatar={aiAvatar}
        onUserDataSubmit={() => setShowCollectUserData(false)}
      />
    </div>
  );
};

type StaticMessageProps = {
  sender: Sender;
  message: string | React.ReactNode;
  backgroundColor: string;
  position: 'left' | 'right';
  showEmailForm?: boolean;
  aiAvatar?: string;
  onUserDataSubmit?: () => void;
};
const FakeMessage: FC<StaticMessageProps> = ({
  sender,
  message,
  backgroundColor,
  position,
  showEmailForm = false,
  aiAvatar,
  onUserDataSubmit,
}) => {
  const { handleSubmitUserData } = useMessengerContext();

  const { valueToHex, isGradient } = useColorPicker(backgroundColor, () => {});
  const hexString = valueToHex();
  const textColor = getTextColor(hexString);
  const borderColor = isGradient ? '#E5EEF3' : addAlpha(textColor, 0.1);

  const supportCollectUserData = {
    fullName: {
      status: false,
      required: false,
    },
    email: {
      status: true,
      required: false,
    },
    phone: {
      status: false,
      required: false,
      countryCode: null,
    },
    status: true,
    after: 0,
  };

  return (
    <>
      <div
        id="message-box"
        className={cn(
          'mb-[15px] inline-flex max-w-full flex-col sm:max-w-[95%]',
          {
            'ml-auto': position === 'right',
            'mr-auto': position === 'left',
          },
        )}
      >
        <div
          className={cn('inline-flex w-fit max-w-full', {
            'flex-row-reverse': position === 'right',
          })}
        >
          {sender === Sender.AI && (
            <div
              className={cn('mt-auto h-[28px] w-[28px]', {
                'ml-[10px]': position === 'right',
                'mr-[10px]': position === 'left',
              })}
            >
              <Avatar
                src={aiAvatar || AILogo.src}
                size={28}
                email=""
                defaultAIAvatar={!aiAvatar}
              />
            </div>
          )}

          <div className="w-fit max-w-full">
            {showEmailForm && (
              <CollectUserData
                className="!mt-0 mb-3 w-full"
                style={{ backgroundColor, textColor, borderColor }}
                handleSubmit={async (value) => {
                  const success = await handleSubmitUserData(value);
                  if (success && onUserDataSubmit) {
                    onUserDataSubmit();
                  }
                }}
                collectUserData={supportCollectUserData}
              />
            )}

            <div
              id="message"
              style={{
                background:
                  backgroundColor ||
                  'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
                color: textColor,
                border: `1px solid ${borderColor}`,
              }}
              className={cn(
                'w-full max-w-full break-words rounded-lg px-[15px] py-3 text-sm sm:max-w-[80%] lg:max-w-full',
                {
                  'ml-auto': position === 'right',
                  'mr-auto': position === 'left',
                },
              )}
            >
              {message}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportRequested;
